import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  createAssociationImage,
  getAssociationData,
  updateB2b,
} from "../../store/actions/association";
import { EditProfileForm } from "../../components/profile/EditProfileForm";
import ActivePlans from "../../components/profile/ActivePlans";
import {
  getPromotions,
  getSponsorData,
  getSponsorDiscounts,
  getSponsorExchanges,
} from "../../store/actions/sponsor";
import { ExchangeCard } from "../../components/products/ExchangeCard";
import { DiscountCard } from "../../components/products/DiscountCard";
import { ASbutton } from "../../components/buttons/ASbutton";
import { AddExchangeModal } from "../../components/modals/AddExchangeModal";

const MySwal = withReactContent(Swal);

export const ExchangesPage = () => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { exchanges, discounts } = useSelector((state) => state.sponsor);
  const theme = useTheme();

  const isFirstUse = useRef(true);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const { adminData } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  //IMAGE
  const { sponsor } = useSelector((state) => state.sponsor);
  const sponsorId = adminData?.sponsorId;
  const [sponsorExchanges, setSponsorExchanges] = useState({});
  const [open, setOpen] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(true);

  const getProductsFromDB = useCallback(async () => {
    try {
      dispatch(getSponsorExchanges(sponsorId));
      dispatch(getSponsorData(sponsorId));
      const currentTimestamp = new Date();
      const active = exchanges.filter(
        (r) => r.status === "active" && r.dueDate.toDate() >= currentTimestamp
      );
      const pending = exchanges.filter(
        (r) => r.status === "pending" && r.dueDate.toDate() >= currentTimestamp
      );
      const expired = exchanges.filter(
        (r) => r.dueDate.toDate() < currentTimestamp
      );
      const rejected = exchanges.filter((r) => r.status === "rejected");

      setSponsorExchanges({
        all: exchanges,
        active: active,
        pending: pending,
        expired: expired,
        rejected: rejected,
      });
    } catch (error) {
      console.log(error);
    }
  }, [sponsorId, dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getProductsFromDB();
      isFirstUse.current = false;
    } else if (exchanges?.length > 0) {
      const active = exchanges.filter((r) => r.status === "active");
      const pending = exchanges.filter((r) => r.status === "pending");
      const expired = exchanges.filter((r) => r.status === "expired");
      const rejected = exchanges.filter((r) => r.status === "rejected");
      // dispatch(getPromotions(sponsorId));
      setSponsorExchanges({
        all: exchanges,
        active: active,
        pending: pending,
        expired: expired,
        rejected: rejected,
      });
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [exchanges, getProductsFromDB]);

  const handleRefresh = () => {
    setLoading(true);
    getProductsFromDB();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const steps = ["Todos", "Activos", "Pendientes", "Expirados", "Rechazados"];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const onClose = () => {
    setOpen(false);
  };
  const exchangesGrid = (type) => {
    if (loading) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
          }}
        >
          <CircularProgress />
        </Grid>
      );
    }
    if (!dataAvailable) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
          }}
        >
          "No hay Descuentos Disponibles"
        </Grid>
      );
    }
    return (
      <>
        <Grid
          container
          mt={4}
          justifyContent={exchanges.length > 4 ? "space-between" : "flex-start"}
          columnGap={{ xs: 1, sm: 6, md: 2 }}
          sx={{ p: 2 }}
        >
          {sponsorExchanges?.[type]?.map((item, index) => (
            <Grid
              item
              container
              md={3}
              lg={2}
              xl={1.5}
              sm={3}
              xs={5}
              xxs={12}
              key={item.id}
              justifyContent={"center"}
              sx={{ md: { paddingLeft: 0, paddingRight: 0 } }}
            >
              <ExchangeCard
                item={item}
                index={index}
                sponsor={sponsor}
                onRefresh={handleRefresh}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={[styles.formCard, { position: "relative" }]}>
        <Box mt={4} ml={4}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Canjes
          </Typography>
        </Box>
        <Box mt={4} ml={4} sx={{ position: "absolute", right: 20, top: -20 }}>
          <ASbutton
            buttonText={"Agregar Canje"}
            onClick={() => setOpen(true)}
          />
        </Box>
        <Tabs
          value={activeTab} // Use activeTab here
          onChange={handleTabChange} // Handle tab changes
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>

        <Box mb={6}>
          {activeTab === 0 && exchangesGrid("all")}
          {activeTab === 1 && exchangesGrid("active")}
          {activeTab === 2 && exchangesGrid("pending")}
          {activeTab === 3 && exchangesGrid("expired")}
          {activeTab === 4 && exchangesGrid("rejected")}
        </Box>
      </Box>
      <AddExchangeModal
        open={open}
        onClose={onClose}
        sponsor={sponsor}
        onRefresh={handleRefresh}
      />
    </Container>
  );
};
