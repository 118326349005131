import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/authReducer";
import usersReducer from "./reducers/usersReducer";
import productsReducer from "./reducers/productsReducer";
import associationReducer from "./reducers/associationReducer";
import { sponsorReducer } from "./reducers/sponsorReducer";
import { blogsReducer } from "./reducers/blogsReducer";

export const store = configureStore(
  {
    middleware: [thunk],
    reducer: {
      auth: authReducer,
      users: usersReducer,
      products: productsReducer,
      association: associationReducer,
      sponsor: sponsorReducer,
      blogs: blogsReducer,
    },
  },

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
