import React, { useState } from "react";
import { Paper, Typography, Link, Box, IconButton } from "@mui/material";
import { styles } from "../../styles/promoCardStyles";
import { ASbutton } from "../buttons/ASbutton";
import Colors from "../../styles/Colors";
import { MdEdit } from "react-icons/md";
import { DiscountModal } from "../modals/DiscountModal";

export const DiscountCard = ({ item, sponsor, onRefresh, handleSponsor }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Paper
        sx={[
          styles.imageContainer,
          {
            backgroundImage: `url(${sponsor.logo})`,
            width: "100%",
            position: "relative",
          },
        ]}
        elevation={0}
      >
        <IconButton
          sx={styles.buttonContainer}
          onClick={() => handleOpenModal()}
        >
          <MdEdit color="white" size={18} />
        </IconButton>
        {/* <img src={sponsor.logo} alt="Product" sx={styles.imageLogo} /> */}
      </Paper>

      <Box sx={styles.main}>
        <Box>
          <Typography variant="body1" sx={styles.cardTitle}>
            {item.name}
          </Typography>
          <Link
            href="#"
            onClick={handleSponsor}
            sx={[styles.cardDescription, { textDecorationLine: "underline" }]}
          >
            {sponsor.name}
          </Link>
          <Typography variant="body1" sx={styles.cardDescription}>
            {item.description}
          </Typography>
        </Box>
      </Box>
      <DiscountModal
        open={open}
        item={item}
        onClose={onClose}
        sponsor={sponsor}
        onRefresh={onRefresh}
      />
    </>
  );
};
