import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom"; // You can use the appropriate navigation link library

import { Avatar, Box, useMediaQuery, useTheme } from "@mui/material";
import { AppBar } from "@mui/material";
import { logoutUser } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // You can import the actual icon you want to use
import NotificationDropdown from "../../components/general/NotificationDropdown";
import { getSponsorData } from "../../store/actions/sponsor";

const drawerWidth = 225;

export const B2bNavbar = ({ openSidebar }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { sponsor } = useSelector((state) => state.sponsor);
  const [sponsorData, setSponsorData] = useState([]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [loading, setloading] = useState(false);
  const isFirstUse = useRef(true);

  const getSponsorFromFB = useCallback(async () => {
    try {
      dispatch(getSponsorData(sponsor?.sponsorId));

      setSponsorData(sponsor);
    } catch (error) {
      console.log(error);
    }
  }, [sponsor]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getSponsorFromFB();
      setSponsorData(sponsor);
      isFirstUse.current = false;
    } else if (!sponsor) {
      getSponsorFromFB();
      setloading(false);

      setSponsorData(sponsor);
    }
  }, [getSponsorFromFB]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/auth/login", { replace: true });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateTo = (route) => {
    navigate(route);
    handleClose();
  };
  const contentStyle = {
    // marginLeft: openSidebar ? "275px" : "150px",
    transition: "margin 0.2s ease",
    width: openSidebar ? "100%" : "100%",
  };
  const mobileContent = {
    marginLeft: 0,
    transition: "margin 0.2s ease",
    width: openSidebar ? "100%" : "100%",
  };
  const Search = styled("div")(() => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    borderColor: "black",
    border: "1px solid grey",

    // backgroundColor: alpha(theme.palette.common.white, 0.75),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(() => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "grey",
  }));

  const StyledInputBase = styled(InputBase)(() => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      color: "grey",

      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "14ch",
        "&:focus": {
          width: "14ch",
        },
      },
    },
  }));

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      disableScrollLock
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      minWidth: 100,

      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],

      borderRadius: "18px",
      borderWidth: "2px !important",
      border: "solid",
      borderColor: "#ECECEE",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#fff !important",
        boxShadow: "none",
        pt: 1,
        borderRadius: "18px",
        borderWidth: "2px",
        border: "solid",
        zIndex: 1000,
        // mt: 1,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          marginLeft: openSidebar ? "250px" : "110px",
          marginRight: openSidebar ? "25px" : "25px",
        }}
      >
        {/* Left side content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "18px",
            borderWidth: "2px !important",
            border: "solid",
            borderColor: "#ECECEE",
            width: "100%",
            backgroundColor: "#fbfafc !important",
          }}
        >
          <div>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                [theme.breakpoints.down("lg")]: {
                  fontSize: "0.95rem !important",
                },

                ml: 2,
              }}
            >
              {sponsor?.name ?? ""}
            </Typography>
          </div>

          {/* Right side content */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "auto",
            }}
          >
            {/* Search box */}
            {/* {aboveTablet && (
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Buscar…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            )} */}
            {/* Notification button */}
            <NotificationDropdown />

            {/* User menu dropdown */}
            {/* <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <MenuIcon sx={{ color: "text.secondary" }} />{" "}
          </IconButton> */}
            <Box
              sx={{
                position: "relative",
                width: "110px",
                mt: 0.5,
                mb: 0.5,
                maxHeight: "35px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "18px",
                borderWidth: "2px !important",
                border: "solid",
                borderColor: "#ECECEE",
                p: 1,
                mr: 1,
              }}
            >
              <Avatar
                onClick={handleMenu}
                alt="User Avatar"
                src={sponsor?.logo}
                sx={{ mr: 1 }}
              />
              {/* <Typography
              variant="body2"
              sx={{ color: theme.palette.primary.main, mr: 2 }}
            >
              {sponsor.email ?? ""}
            </Typography> */}
              <ExpandMoreIcon
                onClick={handleMenu}
                sx={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigateTo("/profile")}>
                  Perfil
                </MenuItem>
                <MenuItem onClick={() => navigateTo("/settings")}>
                  Configuración
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link onClick={handleLogout}>Cerrar Sesión</Link>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
