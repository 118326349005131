import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";

import { styles } from "../../styles/styles";
import { InfoCard } from "../../components/cards/InfoCard";
import { ProfileCard } from "../../components/cards/ProfileCard";

import { useDispatch, useSelector } from "react-redux";
import { getSponsorData } from "../../store/actions/sponsor";
import { ASbutton } from "../../components/buttons/ASbutton";
import { getSponsorBlogs } from "../../store/actions/blogs";
import { BlogsTable } from "../../components/blogs/BlogsTable";
import { PostModal } from "../../components/blogs/PostModal";
const BlogsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { blogs } = useSelector((state) => state.blogs);
  const { adminData } = useSelector((state) => state.auth);

  const isFirstUse = useRef(true);
  const [blogsFromFB, setBlogsFromFB] = useState([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setloading] = useState(false);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const sponsorId = adminData?.sponsorId;
  const [activeUsersFromDB, setActiveUsersFromDB] = useState([]);

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const [dataAvailable, setDataAvailable] = useState(true);

  const getBlogsFromFb = useCallback(async () => {
    try {
      dispatch(getSponsorBlogs(sponsorId));

      setBlogsFromFB(blogs);
    } catch (error) {
      console.log(error);
    }
  }, [blogs]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getBlogsFromFb();

      isFirstUse.current = false;
    } else if (blogs.length > 0) {
      setloading(false);

      setBlogsFromFB(blogs);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setloading(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [getBlogsFromFb]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleRefresh = () => {
    setloading(true);
    getBlogsFromFb();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  useEffect(() => {
    console.log(blogs);
  }, [blogs]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={[styles.formCard, { position: "relative" }]}>
        <Box mt={4} ml={4}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Blogs
          </Typography>
        </Box>
        {/* <Box mt={4} ml={4} sx={{ position: "absolute", right: 20, top: -20 }}>
          <ASbutton buttonText={"Agregar Blog"} onClick={() => setOpen(true)} />
        </Box> */}
        {/* <Tabs
          value={activeTab} // Use activeTab here
          onChange={handleTabChange} // Handle tab changes
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs> */}

        <Box mb={6}>
          <BlogsTable
            items={blogsFromFB}
            handleRefresh={handleRefresh}
            isDataAvailable={dataAvailable}
            loading={loading}
            value={activeTab}
            handleChange={handleTabChange}
          />
        </Box>
      </Box>
      <PostModal
        open={open}
        onClose={onClose}
        onRefresh={handleRefresh}
        mode={"add"}
        blogs={blogsFromFB}
      />
    </Container>
  );
};

export default BlogsPage;
