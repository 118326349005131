import Colors from "./Colors";

export const styles = {
  cardWrapper: {
    display: "flex",
    marginHorizontal: 1,
    alignSelf: "center",
    width: "150px",
    height: "225px",
    marginBottom: 2,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "18px",
  },
  content: {
    display: "flex",
    flex: 1,
    position: "relative",
    width: "160px",
    alignItems: "center",
    justifyContent: "space-between",
    height: "200px",
    flexDirection: "column",
    alignSelf: "center",
    // zIndex: 3000,
    py: 1,
    px: 1,
  },
  contentContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
    alignSelf: "center",
  },
  product: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // alignSelf: "center",

    justifyContent: "flex-start",
    marginLeft: 2,
  },
  cardTitle: {
    fontSize: 16,

    color: Colors.light,
  },
  cardSubTitle: {
    textDecorationLine: "underline",
    color: Colors.light,
    fontSize: 14,

    // marginTop: 0.5,

    color: "white",
    // lineHeight: 18,
  },
  cardDescriptionContainer: {
    // marginBottom: windowSize.height < 750 ? windowSize.width / 100 : 0,
    color: Colors.textLight,
    alignItems: "center",
    justifyContent: "center",
  },
  cardDescription: {
    fontSize: 16,

    lineHeight: 16,
    color: "white",
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  imageContainer: {
    // marginHorizontal: 10,
    alignSelf: "flex-start",
    marginLeft: 1,
    width: 50,
    height: 50,
    // marginRight: 10,
  },
  image: {
    width: "auto",
    height: "100%",

    borderRadius: 50,
  },

  price: {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    backgroundColor: Colors.light,
    borderRadius: 18,
    width: "55px",

    mb: 1,
    ml: 0.5,
  },
  priceNumber: {
    flexDirection: "row",
    textAlign: "center",

    width: "55px",
  },
  priceNumberText: {
    marginHorizontal: 5,
    fontSize: 16,

    textAlign: "center",

    paddingRight: 0.5,
    color: Colors.accent,
  },
  priceIcon: {
    alignSelf: "center",
    // position: 'absolute',
    left: 2,
    bottom: 2,
  },
  allSports: {
    fontSize: 16,

    color: Colors.textLight,
    marginTop: 5,
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: 1,
  },
  divider: {
    alignSelf: "center",
    alignItems: "flex-start",
    justifyContent: "center",
    marginHorizontal: 0,
    flex: 1.3,
    height: 75,
  },
  mainView2: {
    // backgroundColor: Colors.light,
    alignItems: "center",
    justifyContent: "center",

    borderRadius: 14,
    flexDirection: "row",
    alignSelf: "center",
  },
};
