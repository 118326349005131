import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import LogInForm from "../../components/login/LoginForm";
import { RegisterModal } from "../../components/modals/RegisterModal";

export const Login = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <LogInForm setOpen={setOpen} />

      <RegisterModal open={open} onClose={onClose} />
    </Box>
  );
};
