import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ASbutton } from "../buttons/ASbutton";
import { PostModal } from "./PostModal";
import { deleteBlogPost } from "../../store/actions/blogs";
import { MdDelete } from "react-icons/md";

const MySwal = withReactContent(Swal);

export const PostsTable = ({
  items,
  isDataAvailable,
  loading,
  handleRefresh,
  handleChange,
  value,
  blogs,
}) => {
  const handleDelete = (postId, blogId) => {
    console.log("Deleting post with ID:", postId);
    console.log("Belonging to blog with ID:", blogId);
    MySwal.fire({
      title: "Eliminar Post",
      text: "¿Estás seguro de eliminar este post?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteBlogPost(blogId, postId);
        MySwal.fire(
          "Eliminado",
          "El post ha sido eliminado correctamente",
          "success"
        );

        handleRefresh();
      }
    });
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const onClose = () => {
    setEditModalOpen(false);

    setSelectedItem();
  };
  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (isDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!isDataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, isDataAvailable]
  );

  const handleEdit = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditModalOpen(true);
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "postType",
      label: "Tipo",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "tags",
      label: "Tags",
      options: {
        display: true,
        filter: false,
        customBodyRender: (value) => {
          return value ? value.join(", ") : ""; // Add a null check before calling join
        },
      },
    },
    {
      name: "category",
      label: "Categoría",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "blogId",
      label: "Categoría",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const selectedBlog = items.find((item) => item.id === rowData[0]);
          console.log(selectedBlog);

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <ASbutton
                buttonText={"Editar"}
                onClick={() => handleEdit(selectedBlog)}
              ></ASbutton>
            </Box>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const postId = rowData[0]; // Assuming the post ID is in the first column
          const blogId = rowData[5]; // Assuming the blogId is in the seventh column

          return (
            <Box>
              <IconButton
                onClick={() => handleDelete(postId, blogId)}
                title="Eliminar"
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomTitle = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Todos" sx={{ fontSize: 12, paddingBottom: 0 }} />
          <Tab label="Aceptados" sx={{ fontSize: 12, paddingBottom: 0 }} />
          <Tab label="Pendientes" sx={{ fontSize: 12, paddingBottom: 0 }} />
          <Tab label="Rechazados" sx={{ fontSize: 12, paddingBottom: 0 }} />
        </Tabs>
      </Box>
    );
  };
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };

  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10,
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },

    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  return (
    <Box mt={4} sx={{ width: "100%" }}>
      <MUIDataTable
        title={CustomTitle()}
        data={items}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />

      {selectedItem && (
        <PostModal
          open={editModalOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
          mode={"edit"}
          blogs={blogs}
          data={selectedItem}
        />
      )}
    </Box>
  );
};
