import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";

export const ProfileCard = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };

  if (!data) {
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      flexDirection="column"
      sx={{
        [theme.breakpoints.down("md")]: {
          mt: 2,
        },
      }}
    >
      LOADING
    </Grid>;
  }
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      flexDirection="column"
      sx={{
        [theme.breakpoints.down("md")]: {
          mt: 2,
        },
      }}
    >
      <Grid item>
        <img
          src={data?.logo}
          style={{ width: "200px", height: "200px", borderRadius: "50%" }}
          alt="Profile"
        />
      </Grid>
      <Grid item sx={{ width: "100%" }} pl={2} pr={2} mt={2}>
        <Grid container direction="column">
          <Grid item sx={styles.rowB} mb={1}>
            {/* <Typography
              variant="h6"
              sx={{
                fontWeight: "bold !important",
                fontSize: "1.1em !important",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "0.8rem !important",
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem !important",
                },
              }}
            >
              Nombre de Empresa:{" "}
            </Typography> */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold !important",
                fontSize: "1.1em !important",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "0.8rem !important",
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem !important",
                },
              }}
              ml={0.5}
            >
              {data?.businessName}
            </Typography>
          </Grid>
          <Grid item sx={styles.rowB}>
            <Typography variant="body2" mr={1}>
              Cédula Jurídica:{" "}
            </Typography>
            <Typography variant="body2">{data?.businessId}</Typography>
          </Grid>
          <Grid item sx={styles.rowB}>
            <Typography variant="body2" mr={1}>
              Tel.
            </Typography>
            <Typography variant="body2">{data?.phone}</Typography>
          </Grid>
          <Grid item sx={styles.rowB} mr={1}>
            <Typography variant="body2">{data?.email} </Typography>
          </Grid>

          <Grid item sx={styles.rowB}>
            <Link style={{ fontSize: "14px" }} to="/profile">
              Editar
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
