import jwtDecode from "jwt-decode";

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";
import dayjs from "dayjs";
import { ASbutton } from "../../components/buttons/ASbutton";
import {
  MdClose,
  MdNotificationImportant,
  MdSignalCellular1Bar,
} from "react-icons/md";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { DatePicker } from "@mui/x-date-pickers";

import { fsTimestampCreador } from "../../firebase/firebase-config";
import { processRefund } from "../../store/actions/sponsor";
import { useSelector } from "react-redux";
import { refreshToken } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);
export const RefundModal = ({ open, onClose, item, onRefresh }) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const { sponsor } = useSelector((state) => state.sponsor);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [parsedDate2, setParsedDate2] = useState(dayjs("2005-01-01"));

  const [motives, setMotives] = useState([
    { label: "Errores en la gestión del Sponsor", value: "sponsorError" },
    { label: "Errores en la información", value: "informationError" },
    { label: "Errores de la Plataforma Digital", value: "platformError" },
  ]);

  const statuses = ["active", "pending"];
  //   const getInfo = useCallback(async () => {
  //     try {
  //       const userData = await getUserByUserRef(user.userRef);
  //       setUserInfo(userData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, [item]);

  //   useEffect(() => {
  //     console.log(userInfo);
  //     getInfo();
  //   }, [getInfo]);
  //   const selectedPlan = item?.plan;

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  useEffect(() => {
    if (item?.dueDate) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(item.redeemDate);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
      console.log([parsed]);
    }
  }, [item]);
  const validationSchema = Yup.object().shape({
    manager: Yup.string().required("Campo requerido"),
    managerId: Yup.string().required("Campo requerido"),
    motive: Yup.string().required("Campo requerido"),
    details: Yup.string().required("Campo requerido"),
    approve: Yup.boolean().oneOf([true], "Debe aprobar"),
  });
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      manager: "",
      managerId: "",
      motive: "",
      details: "",
      approve: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const dueDate = fsTimestampCreador.fromDate(
        new Date(formik.values.dueDate)
      );

      const confirmResult = await MySwal.fire({
        text: "¿Estás seguro de que quieres editar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        try {
          const tokenToUse = isValidToken(idToken)
            ? idToken
            : await refreshToken();
          const data = {
            redeemId: item.id,
            adminId: sponsor.sponsorId,
            adminEmail: sponsor.email,
            user: values.manager,
            userPersonalId: values.managerId,
            notes: values.details,
            motive: values.motive,
          };
          console.log("DATA:", data);

          const refund = await processRefund(data, tokenToUse);
          MySwal.fire("Cambios Guardados", "", "success");
          onClose();
          onRefresh();
          loadingSwal.close();
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al editar", "error");
          console.log(error);
          onClose();
          loadingSwal.close();
        }
      }
    },
  });
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  useEffect(() => {
    // Initialize formik values when userInfo changes
    formik.setValues({
      manager: "",
      managerId: "",
      motive: "",
      details: "",
    });
  }, []);
  // useEffect(() => {
  //   console.log(user.userId);
  // }, []);

  if (!item) {
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}></DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>;
  }

  useEffect(() => {
    if (item?.redeemDate) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(item.redeemDate);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
      console.log([parsed]);
    }
    if (item?.exchange?.dueDate) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(item?.exchange?.dueDate);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate2(parsed);
    }
  }, [item]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",

          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}>
        {item?.exchange?.name}
        <Typography variant="subtitle2" color="textSecondary">
          Fecha de redención: {parsedDate.format("DD/MM/YYYY")}{" "}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Caducidad del Canje: {parsedDate2.format("DD/MM/YYYY")}{" "}
        </Typography>
      </DialogTitle>
      <IconButton
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          top: 20,
          right: 20,
          color: "white",
        }}
        onClick={onClose}
      >
        <MdClose size={24} />
      </IconButton>
      <form>
        <DialogContent>
          <Grid container sx={[styles.summaryCard]}>
            <Grid
              container
              columnGap={0}
              justifyContent={"space-between"}
              mb={1}
            >
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={4}
                mt={2}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Nombre de el encargado"
                      variant="outlined"
                      name="manager"
                      value={formik.values.manager}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.manager && Boolean(formik.errors.manager)
                      }
                      helperText={
                        formik.touched.manager && formik.errors.manager
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={4}
                mt={2}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Id del encargado"
                      variant="outlined"
                      name="managerId"
                      value={formik.values.managerId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.managerId &&
                        Boolean(formik.errors.managerId)
                      }
                      helperText={
                        formik.touched.managerId && formik.errors.managerId
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={4}
                mt={2}
              >
                <Grid item xxs={12} xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="motive"
                    name="motive"
                    select
                    label="Motivo"
                    value={formik.values.motive}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.motive && Boolean(formik.errors.motive)
                    }
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                      },
                      PopperProps: {
                        disablePortal: true,
                        placement: "bottom-start", // Adjust the placement as needed
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdSignalCellular1Bar />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  >
                    {motives.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              {/* <Grid
                    container
                    columnGap={0}
                    justifyContent={"space-between"}
                    mb={4}
                    mt={2}
                  >
                    <Grid item xs={12} sm={12} md={5.5}>
                      <FormControl fullWidth>
                        <DatePicker
                          label="Fecha de creación"
                          id="cdate"
                          name="cdate"
                          value={parsedDate2}
                          disabled
                          onChange={(newValue) => {
                            formik.setFieldValue("cdate", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={
                                formik.touched.cdate &&
                                Boolean(formik.errors.cdate)
                              }
                              helperText={
                                formik.touched.cdate && formik.errors.cdate
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid> */}
              <Grid
                container
                columnGap={0}
                rowGap={5}
                justifyContent={"space-between"}
                mb={4}
                mt={2}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Detalles"
                      variant="outlined"
                      name="details"
                      value={formik.values.details}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.details && Boolean(formik.errors.details)
                      }
                      helperText={formik.touched.details}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container columnGap={0} justifyContent={"center"}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={10}
                  justifyContent={"center"}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.primary.main}
                  >
                    Certifico que este reembolso cumple con las condiciones de
                    aplicabilidad establecidad en la Política de Reembolso de
                    Coins
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={2}
                  justifyContent={"center"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="approve"
                          name="approve"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("approve", e.target.checked);
                          }}
                          checked={formik.values.approve}
                        />
                      }
                      label="   Si "
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {" "}
          <Grid
            container
            sx={styles.buttonsContainer}
            justifyContent={"flex-end"}
            direction="row"
          >
            <Grid item>
              <ASbutton
                onClick={onClose}
                type="submit"
                backgroundColor={"grey"}
                color={"white"}
                buttonText={"Volver"}
              />
            </Grid>
            <Grid item>
              <Box ml={2}>
                <ASbutton
                  onClick={formik.handleSubmit}
                  backgroundColor={theme.palette.primary.main}
                  color={"white"}
                  buttonText={"Guardar"}
                  disabled={!formik.isValid}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};
