import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";

import { styles } from "../../styles/styles";
import { InfoCard } from "../../components/cards/InfoCard";
import { ProfileCard } from "../../components/cards/ProfileCard";

import { useDispatch, useSelector } from "react-redux";
import { getSponsorData } from "../../store/actions/sponsor";
import { ASbutton } from "../../components/buttons/ASbutton";
import {
  getBlogPosts,
  getAllPosts,
  getSponsorBlogs,
} from "../../store/actions/blogs";
import { BlogsTable } from "../../components/blogs/BlogsTable";
import { useParams } from "react-router-dom";
import { PostsTable } from "../../components/blogs/PostsTable";
import { PostModal } from "../../components/blogs/PostModal";
const PostsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { posts, blogs } = useSelector((state) => state.blogs);
  const { adminData } = useSelector((state) => state.auth);

  const isFirstUse = useRef(true);
  const [postsFromFB, setPostsFromFB] = useState([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setloading] = useState(true);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const sponsorId = adminData?.sponsorId;
  const [activeUsersFromDB, setActiveUsersFromDB] = useState([]);
  const [blogsFromFB, setBlogsFromFB] = useState([]);

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const [dataAvailable, setDataAvailable] = useState(true);
  const getBlogsFromFb = useCallback(async () => {
    try {
      dispatch(getSponsorBlogs(sponsorId));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, sponsorId]);

  const getPostsFromFb = useCallback(async () => {
    try {
      await getBlogsFromFb();
      dispatch(getAllPosts(sponsorId));
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  }, [dispatch, getBlogsFromFb, sponsorId]);

  useEffect(() => {
    const pendingStatus = [];
    const acceptedStatus = [];
    const rejectedStatus = [];

    posts.forEach((post) => {
      switch (post.status) {
        case "pending":
          pendingStatus.push(post);
          break;
        case "accepted":
          acceptedStatus.push(post);
          break;
        case "rejected":
          rejectedStatus.push(post);
          break;
        default:
          break;
      }
    });

    setPostsFromFB({
      all: posts,
      accepted: acceptedStatus,
      pending: pendingStatus,
      rejected: rejectedStatus,
    });

    //setloading(false);
  }, [posts]);

  useEffect(() => {
    if (isFirstUse.current) {
      //setloading(true);
      getPostsFromFb();
      isFirstUse.current = false;
    } else if (postsFromFB.length > 0) {
      //setDataAvailable(true);
      //setloading(false);
    } else {
      //setDataAvailable(false);
      //setloading(true);
    }
  }, [getPostsFromFb, posts]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleRefresh = async () => {
    setloading(true);
    await getPostsFromFb();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  const onClose = () => {
    setOpen(false);
  };
  const steps = ["Todos", "Activos", "Pendientes", "Rechazados"];

  return (
    <Container maxWidth="xxl">
      <Box sx={[styles.formCard, { position: "relative" }]}>
        <Box mt={4} ml={4}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Posts
          </Typography>
        </Box>
        <Box mt={4} ml={4} sx={{ position: "absolute", right: 20, top: -20 }}>
          <ASbutton buttonText={"Agregar Post"} onClick={() => setOpen(true)} />
        </Box>

        <Box mb={6}>
          {activeTab === 0 && (
            <PostsTable
              items={postsFromFB.all}
              handleRefresh={handleRefresh}
              isDataAvailable={dataAvailable}
              loading={loading}
              value={activeTab}
              handleChange={handleTabChange}
              blogId={id}
              blogs={blogs}
            />
          )}
          {activeTab === 1 && (
            <PostsTable
              items={postsFromFB.accepted}
              handleRefresh={handleRefresh}
              isDataAvailable={dataAvailable}
              loading={loading}
              value={activeTab}
              handleChange={handleTabChange}
              blogId={id}
              blogs={blogs}
            />
          )}
          {activeTab === 2 && (
            <PostsTable
              items={postsFromFB.pending}
              handleRefresh={handleRefresh}
              isDataAvailable={dataAvailable}
              loading={loading}
              value={activeTab}
              handleChange={handleTabChange}
              blogId={id}
              blogs={blogs}
            />
          )}
          {activeTab === 3 && (
            <PostsTable
              items={postsFromFB.rejected}
              handleRefresh={handleRefresh}
              isDataAvailable={dataAvailable}
              loading={loading}
              value={activeTab}
              handleChange={handleTabChange}
              blogId={id}
              blogs={blogs}
            />
          )}
        </Box>
      </Box>
      <PostModal
        open={open}
        onClose={onClose}
        onRefresh={handleRefresh}
        mode={"add"}
        blogs={blogs}
      />
    </Container>
  );
};

export default PostsPage;
