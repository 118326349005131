import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";
import dayjs from "dayjs";
import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { DatePicker } from "@mui/x-date-pickers";
import { editUser, getUserByUserRef } from "../../store/actions/users";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import { updateExchange } from "../../store/actions/sponsor";
import { useSelector } from "react-redux";
const MySwal = withReactContent(Swal);
export const ExchangeModal = ({ open, onClose, item, onRefresh, sponsor }) => {
  const theme = useTheme();

  const [parsedDate2, setParsedDate2] = useState(dayjs("2005-01-01"));
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const categories = [
    "Bar/Vida Nocturna",
    "Restaurante/Cafetería",
    "Tienda deportiva",
    "Outfit",
    "Servicios de salud",
    "Recreación al aire libre",
    "Instalación deportiva",
    "Entretenimiento Indoor",
    "Hospedaje",
    "Supermercado/Abarrotes",
    "Tienda de tecnología",
    "Servicios educativos",
    "Estetica/Spa/Cuidado personal",
    "Productos especializados",
    "Eventos deportivos",
    "Otro",
  ];
  const statuses = ["active", "pending"];

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  useEffect(() => {
    if (item?.dueDate) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(item.dueDate);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
    }
    if (item?.cdate) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(item.cdate);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate2(parsed);
    }
  }, [item]);

  const formik = useFormik({
    enableReinitialize: true, // Enable reinitialization when initial values change

    initialValues: {
      name: item.name ?? "",
      categories: categories[0],
      coinPrice: item.coinPrice,
      description: item.description,
      restrictions: item.restrictions,
      dueDate: parsedDate,
      status: item.status ?? "",
    },

    onSubmit: async (values) => {
      const dueDate = fsTimestampCreador.fromDate(
        new Date(formik.values.dueDate)
      );

      const confirmResult = await MySwal.fire({
        text: "¿Estás seguro de que quieres editar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        const data = {
          // businessName: business.name,
          name: values.name ?? "",
          businessName: values.businessName ?? "",
          categories: values.categories ?? "",
          coinPrice: values.coinPrice ?? "",
          description: values.description ?? "",
          restrictions: values.restrictions ?? "",
          dueDate: dueDate,
          status: values.status ?? "",
        };
        try {
          await updateExchange(sponsor.sponsorId, item.id, data);
          MySwal.fire("Cambios Guardados", "", "success");
          onClose();
          onRefresh();
          loadingSwal.close();
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al editar", "error");
          console.log(error);
          onClose();
          loadingSwal.close();
        }
      }
    },
  });
  useEffect(() => {
    // Initialize formik values when userInfo changes
    formik.setValues({
      name: item.name ?? "",
      businessName: item.businessName ?? "",
      categories: categories[0],
      coinPrice: item.coinPrice ?? "",
      description: item.description ?? "",
      restrictions: item.restrictions ?? "",
      dueDate: parsedDate,
      status: item.status ?? "",
      cdate: item.parsedDate2,
    });
  }, [item]);

  if (!item) {
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}></DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>;
  }

  useEffect(() => {
    if (item?.dueDate) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const dueDatejs = formatDate(item.dueDate);
      const parsed = dayjs(dueDatejs);
      setParsedDate(parsed);
    }
  }, [userInfo]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}>{item?.name}</DialogTitle>
      <DialogContent>
        <form>
          <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
            <Grid container sx={styles.summaryCard}>
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                mt={2}
              >
                <Grid item xs={12} sm={12} md={5.5} mb={2} xxs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Nombre"
                      variant="outlined"
                      disabled={userInfo.isActiveMember}
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5.5}
                  mb={{ xxs: 2, xs: 2, sm: 2, md: 0 }}
                  xxs={12}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="Descripción"
                      variant="outlined"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                  mt={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5.5}
                    mb={{ xxs: 2, xs: 2, sm: 2, md: 0 }}
                    xxs={12}
                  >
                    <FormControl fullWidth>
                      <TextField
                        label="Coins"
                        variant="outlined"
                        name="coinPrice"
                        value={formik.values.coinPrice}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.coinPrice &&
                          Boolean(formik.errors.coinPrice)
                        }
                        helperText={
                          formik.touched.coinPrice && formik.errors.coinPrice
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        onChange={formik.handleChange}
                        name={`status`}
                        value={formik.values.status}
                        className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                      >
                        {statuses.map((status) => (
                          <MenuItem value={status} key={status}>
                            {status === "pending" ? "Pendiente" : "Activo"}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                  mt={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5.5}
                    mb={{ xxs: 2, xs: 2, sm: 2, md: 0 }}
                    xxs={12}
                  >
                    <FormControl fullWidth>
                      <DatePicker
                        label="Fecha de creación"
                        id="cdate"
                        name="cdate"
                        value={parsedDate2}
                        disabled
                        onChange={(newValue) => {
                          formik.setFieldValue("cdate", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              formik.touched.cdate &&
                              Boolean(formik.errors.cdate)
                            }
                            helperText={
                              formik.touched.cdate && formik.errors.cdate
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5.5}
                    mb={{ xxs: 2, xs: 2, sm: 2, md: 0 }}
                  >
                    <FormControl fullWidth>
                      <DatePicker
                        label="Fecha de Vencimiento"
                        id="dueDate"
                        name="dueDate"
                        value={parsedDate}
                        onChange={(newValue) => {
                          formik.setFieldValue("dueDate", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              formik.touched.dueDate &&
                              Boolean(formik.errors.dueDate)
                            }
                            helperText={
                              formik.touched.dueDate && formik.errors.dueDate
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                  mt={{ xxs: 0, xs: 0, sm: 0, md: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5.5}
                    mb={{ xxs: 2, xs: 2, sm: 2, md: 0 }}
                    xxs={12}
                  >
                    <FormControl fullWidth>
                      <TextField
                        label="Restricciones"
                        variant="outlined"
                        name="restrictions"
                        value={formik.values.restrictions}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.restrictions &&
                          Boolean(formik.errors.restrictions)
                        }
                        helperText={
                          formik.touched.restrictions &&
                          formik.errors.restrictions
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            sx={styles.buttonsContainer}
            justifyContent={"flex-end"}
            direction="row"
          >
            <Grid item>
              <ASbutton
                onClick={onClose}
                type="submit"
                backgroundColor={"grey"}
                color={"white"}
                buttonText={"Volver"}
              />
            </Grid>
            <Grid item>
              <Box ml={2}>
                <ASbutton
                  onClick={formik.handleSubmit}
                  backgroundColor={theme.palette.primary.main}
                  color={"white"}
                  buttonText={"Guardar"}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>{/* Your actions or buttons here */}</DialogActions>
    </Dialog>
  );
};
