import { db, storageRef } from "../../firebase/firebase-config";

export const SET_SPONSOR = "SET_SPONSOR";

export const SET_EXCHANGES = "SET_EXCHANGES";
export const SET_DISCOUNTS = "SET_DISCOUNTS";
export const SET_REDEEMS = "SET_REDEEMS";
export const LOGOUT_SPONSOR = "LOGOUT_SPONSOR";

export const getSponsorFromDB = (uid) => {
  return db.doc(`admins/${uid}`).get();
};

export const getItems = (sponsorRef, category) => {
  return sponsorRef.collection(category).get();
};

export const getSponsorData = (sponsorId) => {
  return async (dispatch) => {
    try {
      const sponsorRef = db.collection("sponsors").doc(sponsorId);
      const sponsorSnapshot = await sponsorRef.get();

      if (sponsorSnapshot.exists) {
        const sponsorData = {
          ...sponsorSnapshot.data(),
          sponsorId: sponsorId,
        };

        dispatch(getSponsor(sponsorData));

        return sponsorData;
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getSponsorExchanges = (sponsorId) => {
  return async (dispatch) => {
    try {
      const sponsorRef = db
        .collection("sponsors")
        .doc(sponsorId)
        .collection("exchanges");
      const exchangesSnapshot = await sponsorRef.get();

      const exchanges = [];

      exchangesSnapshot.forEach((doc) => {
        const exchangeData = doc.data();
        exchanges.push({
          id: doc.id,
          ...exchangeData,
        });
      });

      dispatch({
        type: SET_EXCHANGES,
        exchanges: exchanges,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getSponsorDiscounts = (sponsorId) => {
  return async (dispatch) => {
    try {
      const sponsorRef = db
        .collection("sponsors")
        .doc(sponsorId)
        .collection("promotions");
      const discSnapshot = await sponsorRef.get();

      const discounts = [];

      discSnapshot.forEach((doc) => {
        const discountData = doc.data();
        discounts.push({
          id: doc.id,
          ...discountData,
        });
      });

      dispatch({
        type: SET_DISCOUNTS,
        discounts: discounts,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export async function uploadImage(file, sponsorRef, type, idItem) {
  const sponsorItemRef = storageRef
    .ref()
    .child(`sponsor/${sponsorRef}/${type}/${idItem}.jpg`);

  try {
    const putImage = await sponsorItemRef.put(file);

    const url = await putImage.ref.getDownloadURL();

    await db
      .collection("sponsors")
      .doc(sponsorRef)
      .collection(type)
      .doc(idItem)
      .update({
        imageURL: `${url}`,
      });
  } catch (error) {
    console.error(error);
  }
}

export const getRedeemsFromSponsor = (sponsorId) => {
  return async (dispatch) => {
    try {
      const sponsorRef = db
        .collection("sponsors")
        .doc(sponsorId)
        .collection("redeemHistory");

      const redSnapshot = await sponsorRef.get();

      const redeemsWithUserData = [];

      // Use Promise.all to fetch user data concurrently for each redeem
      await Promise.all(
        redSnapshot.docs.map(async (doc) => {
          const redeemData = doc.data();
          const userData = await getUserFromRedeem(redeemData.user);
          redeemsWithUserData.push({
            id: doc.id,
            ...redeemData,
            user: userData,
          });
        })
      );

      // Now you have an array of redeems with associated user data

      // Dispatch the redeems to your Redux store or perform any other actions you need.
      // Example dispatch:
      dispatch({
        type: SET_REDEEMS,
        redeems: redeemsWithUserData,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const processRefund = async (data, token) => {
  const url = `https://us-central1-allsports-69231.cloudfunctions.net/exchanges/redeem/${data.redeemId}`;
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      request: "refund", // consistent naming
      adminId: data.adminId,
      adminEmail: data.adminEmail,
      user: data.user,
      userPersonalId: data.userPersonalId,
      motive: data.motive,
      notes: data.notes,
    }),
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      // Check if the HTTP response status is not okay (not 2xx)
      const errorResponse = await response.json();
      throw new Error(`Request failed: ${errorResponse.message}`);
    }

    const res = await response.json();
    console.log("response:", response);
    console.log("res:", res);
    return res;
  } catch (error) {
    console.error("Error processing refund:", error.message);
    throw new Error(error.message);
  }
};

export const handleRefund = async (
  uid,
  sponsorId,
  redeemId,
  refundHistory,
  refundData,
  refundedCoins,
  wallet
) => {
  const refundDatatoAdd = [
    {
      motive: refundData.motive,
      notes: refundData.notes,
    },
  ];

  const newSportCoins = refundedCoins + wallet.sportCoins;
  const newOverallSportCoins = refundedCoins + wallet.overallSportCoins;

  try {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("redeemHistory")
      .doc(redeemId)
      .update(refundHistory, { refundData: refundDatatoAdd });

    await db.doc(`users/${uid}`).collection("private").doc("wallet").update({
      sportCoins: newSportCoins,
      overallSportCoins: newOverallSportCoins,
    });

    await db
      .collection("users")
      .doc(uid)
      .collection("redeems")
      .doc(redeemId)
      .update(refundHistory, { refundData: refundDatatoAdd });
  } catch (error) {
    console.log(error);
  }
};

export async function uploadImageProfile(file, sponsorRef) {
  const sponsorItemRef = storageRef
    .ref()
    .child(`sponsor/${sponsorRef}/logo.jpg`);

  try {
    const putImage = await sponsorItemRef.put(file);

    const url = await putImage.ref.getDownloadURL();

    await db
      .collection("sponsors")
      .doc(sponsorRef)
      .update({
        logo: `${url}`,
      });
  } catch (error) {
    console.error(error);
  }
}

export async function updateImagePromotion(file, sponsorRef, type, idItem) {
  try {
    const url = file;

    await db
      .collection("sponsors")
      .doc(sponsorRef)
      .collection(type)
      .doc(idItem)
      .update({
        imageURL: `${url}`,
      });
  } catch (error) {
    console.error(error);
  }
}

export async function deleteImage(sponsorRef, type, file) {
  const sponsorItemRef = storageRef
    .ref()
    .child(`sponsor/${sponsorRef}/${type}/${file}`);
  try {
    await sponsorItemRef.delete();
  } catch (error) {
    console.error(error);
  }
}

export const getSponsorLocations = async () => {
  try {
    const sponsorLocations = [];

    const sponsorsSnapshot = await db.collection("sponsors").get();

    for (const sponsorDoc of sponsorsSnapshot.docs) {
      const businessName = sponsorDoc.data().name;
      const sponsorId = sponsorDoc.id;

      const branchesSnapshot = await db
        .collection("sponsors")
        .doc(sponsorId)
        .collection("branches")
        .get();

      branchesSnapshot.docs.forEach((branchDoc) => {
        const location = branchDoc.data().location;
        sponsorLocations.push({ businessName, location });
      });
    }

    return sponsorLocations;
  } catch (error) {
    console.log(error);
  }
};
export const addBranchesToSponsor = async (sponsorId, branchesData) => {
  try {
    const sponsorRef = db.collection("sponsors").doc(sponsorId);
    const branchesCollectionRef = sponsorRef.collection("branches");

    for (const branchData of branchesData) {
      await branchesCollectionRef.add(branchData);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBranches = async (sponsorId) => {
  const branches = [];

  try {
    const sponsorBranch = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .get();

    sponsorBranch.docs.forEach((doc) =>
      branches.push({ id: doc.id, ...doc.data() })
    );

    // console.log(branches);

    return branches;
  } catch (error) {
    console.log(error);
  }
};
export const createBranches = async (sponsorId, branchData) => {
  try {
    const sponsorRef = db.collection("sponsors").doc(sponsorId);
    const branchesCollection = sponsorRef.collection("branches");

    // Add the branch data to the branches collection
    await branchesCollection.add(branchData);
  } catch (error) {
    console.log(error);
  }
};

export const editBranches = async (sponsorId, branchData, branchId) => {
  try {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .doc(branchId)
      .set(branchData, { merge: true });
  } catch (error) {
    console.log(error);
  }
};
export const createBranch = async (sponsorId, branchesData) => {
  try {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .add(branchesData);
    console.log("documento creado");
  } catch (error) {
    console.log(error);
  }
};
export const updateExchange = async (sponsorId, exchangeId, updates) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .doc(exchangeId)
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};
export const createExchange = async (sponsorId, updates) => {
  try {
    const newExchangeRef = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .add(updates);

    console.log("New document added with ID: ", newExchangeRef.id);
  } catch (error) {
    console.error("Error adding new document: ", error);
  }
};
export const updatePromos = async (sponsorId, promotionId, updates) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .doc(promotionId)
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const createPromo = async (sponsorId, updates) => {
  try {
    const newPromoRef = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .add(updates);

    console.log("New document added with ID: ", newPromoRef.id);
  } catch (error) {
    console.error("Error adding new document: ", error);
  }
};

export const updatePromotionImage = async (
  promotionId,
  newPromotionImg,
  sponsorId
) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .doc(promotionId)
      .set(newPromotionImg, { merge: true });
  } catch (error) {
    console.log(error);
  }
};
export const getBranch = async (sponsorId, branchId) => {
  try {
    const sponsorRef = db.collection("sponsors").doc(sponsorId);
    const branchesCollection = sponsorRef.collection("branches");

    // Retrieve the branch data from the Firestore collection
    const branchDoc = await branchesCollection.doc(branchId).get();

    if (branchDoc.exists) {
      // Branch document exists, you can access its data using .data()
      const branchData = branchDoc.data();
      return branchData;
    } else {
      // Branch document doesn't exist
      return null;
    }
  } catch (error) {
    console.error("Error getting branch:", error);
    throw error;
  }
};
export const deleteBranch = (sponsorId, branchId) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .doc(branchId)
      .delete();
    console.log("Sucursal eliminada");
  } catch (error) {
    console.log(error);
  }
};
export const getUserFromRedeem = async (uid) => {
  try {
    const data = await db.collection("users").doc(uid).get();
    return data.data();
  } catch (error) {
    console.log(error);
    return null;
  }
};

//IMAGES
export const updateSponsor = async (sponsorId, updates) => {
  try {
    db.collection("sponsors").doc(sponsorId).set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const createSponsorImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;

  try {
    const path = `sponsors/${imageUpload.name}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    if (fileUrl) {
      const newImg = { logo: fileUrl };
      await updateSponsor(id, newImg);
    }
  } catch (error) {
    console.log(error);
  }
};
export const createSponsorImagePreview = async (imageUpload, id) => {
  const imageName = imageUpload.name;

  try {
    const path = `sponsorsTemp/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    if (fileUrl) {
      const newImg = { imageURL: fileUrl };
      await updateSponsor(id, newImg);
    }
  } catch (error) {
    console.log(error);
  }
};
export const uploadFile = async (path, file) => {
  if (file === null) return;
  console.log(path, file);
  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path, error);
    console.log("No se puede subir el archivo seleccionado");
  }
};

export const sendForm = async (data) => {
  try {
    const requestRef = await db
      .collection("contacts")
      .doc("sponsorRequests")
      .collection("requests")
      .add(data);

    console.log("Solicitud creada with ID:", requestRef.id);

    return requestRef.id;
  } catch (error) {
    console.error("Error creating request:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const getSponsor = (sponsor) => ({
  type: SET_SPONSOR,
  payload: {
    sponsor,
  },
});

export const getExchanges = (exchanges) => ({
  type: SET_EXCHANGES,
  payload: {
    exchanges,
  },
});

export const getDiscounts = (promotions) => ({
  type: SET_DISCOUNTS,
  payload: {
    promotions,
  },
});
export const getRedeems = (redeems) => ({
  type: SET_REDEEMS,
  payload: {
    redeems,
  },
});

export const logoutSponsor = () => ({
  type: LOGOUT_SPONSOR,
  payload: {},
});
