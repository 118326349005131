import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { EditProfileForm } from "../../components/profile/EditProfileForm";

import {
  createSponsorImage,
  getBranches,
  getSponsorData,
  getSponsorLocations,
  updateSponsor,
} from "../../store/actions/sponsor";

import EditBranches from "../../components/branches/EditBranches";

const MySwal = withReactContent(Swal);

export const EditProfile = () => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { sponsor } = useSelector((state) => state.sponsor);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const isFirstUse = useRef(true);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const [selected, setSelected] = useState([]);
  const countryList = [{ name: "Costa Rica" }];
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const numberOfBranches = 1; // Define the total number of branches
  const [atLeastOneBranchCompleted, setAtLeastOneBranchCompleted] =
    useState(false);
  const [data, setData] = useState({});
  const [branches, setBranches] = useState(
    Array.from({ length: numberOfBranches }, () => ({}))
  );

  const getSponsorBranches = useCallback(async () => {
    try {
      const branchData = await getBranches(sponsor?.sponsorId);
      dispatch(getSponsorData(sponsor?.sponsorId));
      setBranches(branchData);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getSponsorBranches();
      isFirstUse.current = false;
    } else if (!sponsor) {
      setLoading(true);
      getSponsorBranches();
    } else if (sponsor) {
      setData(sponsor);
      setLoading(false);
    }
  }, [getSponsorBranches, sponsor]);

  const handleRefresh = () => {
    setLoading(true);

    getSponsorBranches();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sponsorLocations = await getSponsorLocations();
        setLocations(sponsorLocations);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleNext = (newData) => {
    // console.log("click");
    setData((prev) => ({ ...prev, ...newData }));

    // console.log("Data after handleNext:", { ...data, ...newData });

    handleRegister(newData);
    // console.log(newData);

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleRegister = async (values) => {
    const sponsorProducts = selected;
    const updatedData = {
      typeId: "Cédula Jurídica",
      businessId: values.businessId,
      name: values.name,
      category: values.category,
      country: values.countries,
      facebook: values.facebook,
      instagram: values.instagram,
      tiktok: values.tiktok,
      whatsApp: values.whatsApp,
      country: "Costa Rica",
      products: sponsorProducts,
      address: values.address,
      phone: values.phone,
      isActive: true,
      // logo: image ?? "",
    };

    console.log(values);
    const confirmResult = await MySwal.fire({
      title: "Confirmar edición",
      text: "¿Estás seguro de que deseas editar tu información?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, editar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      try {
        if (imageUpload !== "") {
          createSponsorImage(imageUpload, sponsor.sponsorId);
        }

        await updateSponsor(sponsor.sponsorId, updatedData);
        handleRefresh();

        MySwal.fire("Guardado!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al editar la asociación", "error");
      }

      handleRefresh();
      setLoading(false);
    }
  };

  const steps = ["Perfil", "Sucursales"];

  //   const handleNext = (newData) => {
  //     setData((prev) => ({ ...prev, ...newData }));

  //     // console.log("Data after handleNext:", { ...data, ...newData });

  //     if (activeStep === 2) {
  //       handleRegister(newData);

  //       return;
  //     }
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={styles.formCard}>
        <Tabs
          value={activeTab} // Use activeTab here
          onChange={handleTabChange} // Handle tab changes
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>

        <Box>
          {activeTab === 0 && (
            <EditProfileForm
              handleNext={handleNext}
              handleBack={handleBack}
              loading={loading}
              data={data}
              handleRegister={handleRegister}
              activeStep={activeStep}
              loadUrl={setFileUrl}
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              setImage={setImage}
              image={image}
              setSelected={setSelected}
              selected={selected}
              handleRefresh={handleRefresh}
            />
          )}
        </Box>
        {/* {activeStep === 1 && (
            <> */}
        <Button onClick={() => setShowModal(true)}>Editar Sucursales</Button>
        <Box>
          {showModal && (
            <EditBranches
              setBranches={setBranches}
              branches={branches}
              sponsorId={sponsor?.sponsorId}
              open={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};
