import Colors from "./Colors";

export const styles = {
  main: {
    backgroundColor: "#D4D8E0",
    borderBottomLeftRadius: "18px",
    borderBottomRightRadius: "18px",
    width: "100%",

    pl: 1,
    pb: 1,
  },
  buttonContainer: {
    position: "absolute",
    right: 5,
    top: 5,
    backgroundColor: Colors.accent,
  },
  imageContainer: {
    display: "flex",

    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "flex-end",
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    overflow: "hidden",
    // justifyContent: "flex-end",

    width: "100%",
    height: "120px",
  },
  imageLogo: {
    width: "auto",
    height: "100%",
  },

  image: {
    width: "auto",
    height: "100%",
  },

  cardText: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "325px",

    flexWrap: "wrap",
  },
  cardTitle: {
    width: "100%",
    fontSize: 15,

    color: Colors.dark,
  },
  cardDescription: {
    fontSize: 12,

    color: Colors.dark,
  },
  shadowViewStyle: { alignSelf: "stretch" },
  mainView2: {
    width: "450px",
    height: "250px",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 1,
    borderRadius: "14px",
    flexDirection: "row",
    alignSelf: "center",
  },
};
