export default {
  first: "#7265E3",
  second: "#CF8B00",
  third: "#000",
  fourth: "#1A2947",
  accent: "#7265E3",
  accentMid: "#7D85F9",
  accentLight: "#B68BCF",
  disabled: "#E4DFFF",
  cyan: "#7FE3F0",
  softCyan: "#75BFCF",
  blue: "#59B9FD",
  textLight: "#6E7b98",
  textDark: "#5E7483",
  textMid: "#6E7b98",
  textGrey: "#6E6B78",
  textBlue: "#092C4C",
  grey: "#606060",
  greyLight: "#D9D9D9",
  greyLighter: "#F5F5F5",
  softGrey: "#E7E7E7",
  bg: "#F0F4FF",
  brown: "#AA854F",
  blueGray: "#93A6BF",
  bgBlue: "#365da1",
  dark: "#000",
  light: "#FFFFFF",
  gradient1: "#AF8EFF",
  gradient2: "#7FE3F0",
  greenActive: "#7FE3F0",

  facebook: "#3b5998",
  google: "#de5246",

  green: "#6ABF6A",
  red: "#FF2222",

  bgTransparent: "rgba(241,244,255,1.0)",

  transparent: "rgba(0,0,0,0.0)",
  semiTransparent: "rgba(0,0,0,0.8)",
};
