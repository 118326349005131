import theme from "./asTheme";

export const styles = {
  rowB: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 15,
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  title: {
    fontFamily: `'Lexent', sans-serif`,
    color: "#7265E3 !important",
  },
  infoSection: {
    minHeight: "50vh",
    padding: "0px important",

    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    // minHeight: "50vh",
    paddingRight: "20px",
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    paddingBottom: "20px",
  },
  formContainerAcceptance: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",

    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    paddingBottom: "20px",
  },
  formCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    minHeight: "63vh",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0.8,
    },
    paddingBottom: "20px",
  },
  formCardSecond: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    minHeight: "63vh",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 6,

    [theme.breakpoints.down("sm")]: {
      width: "auto",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      marginTop: 4,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 4,
    },
    paddingBottom: "20px",
  },
  formCardUser: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    minHeight: "25vh",
    p: 2,
    mb: 4,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      marginTop: 4,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 2,
    },
  },
  plansCard: {
    display: "flex",
    flexDirection: "column",
    // minHeight: "70vh",

    alignItems: "center",

    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  requestCard: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",

    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  plansCardGenerate: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",

    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  plansCardAcceptance: {
    display: "flex",
    flexDirection: "column",
    minHeight: "70vh",

    alignItems: "center",

    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  planSelection: {
    borderRadius: "18px",
    minHeight: "400px",
    maxHeight: "400px",
    overflowY: "scroll",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100% !important",
    overflowY: "scroll",
    pb: 4,
  },

  planSelectionLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "18px",
    minHeight: "325px",
    maxHeight: "325px",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
  },
  formTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  buttonsContainer: {
    // marginTop: "4em",
    marginTop: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2em",
    },
  },
  summaryCard: {
    width: "100%",
    borderRadius: "18px",
    position: "relative",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    p: 2,
  },
  requestSummaryCard: {
    width: "100%",
    borderRadius: "18px",
    position: "relative",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    pr: 2,
    pl: 2,
    py: 1,
  },
  summaryCard2: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    borderRadius: "18px",
    minHeight: "325px",
    maxHeight: "325px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    pt: 2,
    pb: 2,
    pr: 1,
    pl: 1,
  },
  planSelection2: {
    position: "relative",
    borderRadius: "18px",
    minHeight: "325px",
    maxHeight: "325px",
    overflowY: "scroll",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100% !important",
    overflowY: "scroll",
    pb: 4,
  },
  qrCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    p: 2,
  },

  overlay: {
    position: "relative",
    userSelect: "none",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "18px",
    alignItems: "center",
    justifyContent: "center",
    p: 2,
    mb: 4,
    zIndex: 999,
  },
  overlayPlans: {
    position: "relative",
    userSelect: "none",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "18px",
    alignItems: "center",
    justifyContent: "center",
    p: 2,
    mb: 4,
    zIndex: 999,
  },
};
