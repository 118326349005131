import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import { B2bNavbar } from "./B2bNavbar";

const SidebarLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [marginLeft, setMarginLeft] = useState(0);
  const [mobileVerticalDrawerOpen, setMobileVerticalDrawerOpen] =
    useState(false);
  useEffect(() => {
    // Calculate the margin left based on the screen width and mobileVerticalDrawerOpen state
    const calculatedMarginLeft = mobileVerticalDrawerOpen
      ? window.innerWidth * 0.18
      : 0;
    setMarginLeft(calculatedMarginLeft);
  }, [mobileVerticalDrawerOpen]);

  const handleDrawerToggle = () => {
    if (mobile) {
      setMobileVerticalDrawerOpen(!mobileVerticalDrawerOpen);
    } else {
      setOpen(!open);
    }
  };

  const contentStyle = {
    marginLeft: open ? "225px" : "75px",
    transition: "margin 0.2s ease",
  };
  const mobileContent = {
    marginLeft: `${marginLeft}px`,
    transition: "margin 0.2s ease",
  };
  return (
    <>
      <Sidebar
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        setOpen={setOpen}
        mobileVerticalDrawerOpen={mobileVerticalDrawerOpen}
        setMobileVerticalDrawerOpen={setMobileVerticalDrawerOpen}
      />
      {!mobile && <B2bNavbar openSidebar={open} contentStyle={contentStyle} />}
      <Container
        maxWidth={mobile ? false : "xxl"}
        sx={{ mb: 4, mt: mobile ? 0 : 1, padding: mobile && 0 }}
      >
        <Box sx={mobile ? mobileContent : contentStyle}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
};

export default SidebarLayout;
