import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";
import dayjs from "dayjs";
import { ASbutton } from "../../components/buttons/ASbutton";
import {
  MdClose,
  MdNotificationImportant,
  MdSignalCellular1Bar,
} from "react-icons/md";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { DatePicker } from "@mui/x-date-pickers";
import { editUser, getUserByUserRef } from "../../store/actions/users";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import { createPromo, updatePromos } from "../../store/actions/sponsor";

const MySwal = withReactContent(Swal);

export const AddDiscountModal = ({
  open,
  onClose,

  onRefresh,
  sponsor,
}) => {
  const theme = useTheme();

  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [parsedDate2, setParsedDate2] = useState(dayjs("2005-01-01"));
  const [userInfo, setUserInfo] = useState([]);

  const categories = [
    "Bar/Vida Nocturna",
    "Restaurante/Cafetería",
    "Tienda deportiva",
    "Outfit",
    "Servicios de salud",
    "Recreación al aire libre",
    "Instalación deportiva",
    "Entretenimiento Indoor",
    "Hospedaje",
    "Supermercado/Abarrotes",
    "Tienda de tecnología",
    "Servicios educativos",
    "Estetica/Spa/Cuidado personal",
    "Productos especializados",
    "Eventos deportivos",
    "Otro",
  ];
  const statuses = ["active", "pending"];

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),

    description: Yup.string()
      .required("Campo requerido")
      .max(30, "La descripción debe tener como máximo 30 caracteres"),
    restrictions: Yup.string().required("Campo requerido"),
    restrictions: Yup.string().required("Campo requerido"),
    dueDate: Yup.date().required("Campo requerido"),
  });

  //   useEffect(() => {
  //     if (item?.dueDate) {
  //       // Convert the user's birthday (timestamp or string) to a Dayjs object
  //       const birthdayDayjs = formatDate(item.dueDate);
  //       const parsed = dayjs(birthdayDayjs);
  //       setParsedDate(parsed);
  //     }
  //   }, [userInfo]);

  const formik = useFormik({
    enableReinitialize: true, // Enable reinitialization when initial values change

    initialValues: {
      name: "",
      categories: "",

      description: "",
      restrictions: "",
      dueDate: parsedDate,
      status: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const dueDate = fsTimestampCreador.fromDate(
        new Date(formik.values.dueDate)
      );

      const confirmResult = await MySwal.fire({
        text: "¿Estás seguro de que quieres editar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        const data = {
          // businessName: business.name,
          name: values.name ?? "",
          categories: values.categories ?? "",
          cdate: new Date(),
          description: values.description,
          restrictions: values.restrictions,
          dueDate: dueDate,
          status: "pending",
        };
        try {
          await createPromo(sponsor.sponsorId, data);
          MySwal.fire("Cambios Guardados", "", "success");
          onClose();
          onRefresh();
          loadingSwal.close();
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al editar", "error");
          console.log(error);
          onClose();
          loadingSwal.close();
        }
      }
    },
  });

  //   if (!loading) {
  //     <Dialog
  //       open={open}
  //       onClose={onClose}
  //       maxWidth={"md"}
  //       PaperProps={{
  //         sx: {
  //           // backgroundColor: theme.palette.secondary.secondary,
  //           width: "100%",
  //           backgroundImage: "none",
  //           overflowX: "hidden",
  //         },
  //       }}
  //     >
  //       <DialogTitle color={theme.palette.primary.main}></DialogTitle>
  //       <DialogContent>
  //         <CircularProgress />
  //       </DialogContent>
  //     </Dialog>;
  //   }

  //   useEffect(() => {
  //     if (item?.dueDate) {
  //       // Convert the user's birthday (timestamp or string) to a Dayjs object
  //       const birthdayDayjs = formatDate(item.dueDate);
  //       const parsed = dayjs(birthdayDayjs);
  //       setParsedDate(parsed);
  //     }
  //     if (item?.cdate) {
  //       // Convert the user's birthday (timestamp or string) to a Dayjs object
  //       const birthdayDayjs = formatDate(item.cdate);
  //       const parsed = dayjs(birthdayDayjs);
  //       setParsedDate2(parsed);
  //     }
  //   }, [item]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",

          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}>
        Crear Descuento
        <IconButton
          sx={{
            backgroundColor: theme.palette.primary.main,
            position: "absolute",
            right: 20,
            color: "white",
          }}
          onClick={onClose}
        >
          <MdClose size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
            <Grid container sx={styles.summaryCard}>
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={4}
                mt={2}
              >
                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={{ md: 4 }}
                  mt={2}
                >
                  <Grid item xs={12} sm={12} md={5.5} mb={4} mt={2}>
                    <FormControl fullWidth>
                      <TextField
                        label="Nombre"
                        variant="outlined"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xxs={12} xs={12} md={5.5} mb={4} mt={2}>
                    <TextField
                      fullWidth
                      id="categories"
                      name="categories"
                      select
                      label="Categoría"
                      value={formik.values.categories}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.categories &&
                        Boolean(formik.errors.categories)
                      }
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start", // Adjust the placement as needed
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdSignalCellular1Bar />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    >
                      {categories.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>{" "}
                  {/* <Grid item xs={12} md={5.5}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        onChange={formik.handleChange}
                        name={`status`}
                        value={formik.values.status}
                        className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                      >
                        {statuses.map((status) => (
                          <MenuItem value={status} key={status}>
                            {status === "pending" ? "Pendiente" : "Activo"}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>

                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={4}
                  mt={2}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <DatePicker
                        label="Fecha de Vencimiento"
                        id="dueDate"
                        name="dueDate"
                        value={parsedDate}
                        onChange={(newValue) => {
                          formik.setFieldValue("dueDate", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              formik.touched.dueDate &&
                              Boolean(formik.errors.dueDate)
                            }
                            helperText={
                              formik.touched.dueDate && formik.errors.dueDate
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columnGap={0}
                  rowGap={5}
                  justifyContent={"space-between"}
                  mb={4}
                  mt={2}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Descripción"
                        variant="outlined"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Restricciones"
                        variant="outlined"
                        name="restrictions"
                        multiline
                        value={formik.values.restrictions}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.restrictions &&
                          Boolean(formik.errors.restrictions)
                        }
                        helperText={
                          formik.touched.restrictions &&
                          formik.errors.restrictions
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            sx={styles.buttonsContainer}
            justifyContent={"flex-end"}
            direction="row"
          >
            <Grid item>
              <ASbutton
                onClick={onClose}
                type="submit"
                backgroundColor={"grey"}
                color={"white"}
                buttonText={"Volver"}
              />
            </Grid>
            <Grid item>
              <Box ml={2}>
                <ASbutton
                  onClick={formik.handleSubmit}
                  backgroundColor={theme.palette.primary.main}
                  color={"white"}
                  buttonText={"Crear"}
                  disabled={!formik.isValid}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>{/* Your actions or buttons here */}</DialogActions>
    </Dialog>
  );
};
