import React, { useEffect, useRef } from "react";
import {
  CardContent,
  CardMedia,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import fallback from "../../assets/imgDrop.png";

const UploadImage = ({
  setImageUpload,
  imageUpload,
  setImage,
  image,
  inputId,
}) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const uploadImage = async () => {
      setImageUpload(imageUpload);
    };

    if (imageUpload === null) {
      return;
    } else {
      uploadImage();
    }
  }, [imageUpload]);

  const removeImage = () => {
    setImageUpload(null);
    setImage("");
  };

  const handleClickImage = (event) => {
    // Prevent triggering the file input's click event
    event.preventDefault();

    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        elevation: 1,
        // width: "100px",
        // height: "100px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {imageUpload !== null && image !== "" && (
          <IconButton
            size="small"
            onClick={removeImage}
            aria-label="Remove Image"
            sx={{
              position: "absolute",
              right: -20,
              top: -10,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        )}
      </Box>
      <CardContent>
        <label htmlFor={inputId ? inputId : "file-input"}>
          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={image === "" ? fallback : image}
            sx={{
              objectFit: "cover",
              width: "100px",
              height: "100px",
              cursor: "pointer",
              borderRadius: "18px",
            }}
            onClick={handleClickImage} // Handle click on the image
          />
        </label>
        <input
          ref={fileInputRef}
          type="file"
          name="sponsor"
          id={inputId ? inputId : "file-input"}
          style={{ display: "none" }}
          onChange={(event) => {
            setImageUpload(event.target.files[0]);
            setImage(URL.createObjectURL(event.target.files[0]));
          }}
        />
      </CardContent>
    </Box>
  );
};

export default UploadImage;
