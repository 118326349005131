import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";
import dayjs from "dayjs";
import { ASbutton } from "../buttons/ASbutton";

import { TagsInput } from "react-tag-input-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  addBlogPost,
  createPostImage,
  createPostImages,
  editBlogPost,
  getCategories,
} from "../../store/actions/blogs";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../general/UploadImage";
import UploadImageMultiple from "../general/UploadImageMultiple";
import { MdClose } from "react-icons/md";
import { fsTimestampCreador } from "../../firebase/firebase-config";
const MySwal = withReactContent(Swal);
export const PostModal = ({ open, onClose, data, onRefresh, mode, blogs }) => {
  const theme = useTheme();
  const { sponsor } = useSelector((state) => state.sponsor);
  const { adminData } = useSelector((state) => state.auth);
  const [imageUploads, setImageUploads] = useState([]);
  const [isLoadingImg, setIsLoadingImg] = useState(true);

  const [images, setImages] = useState([]);
  const [postId, setPostId] = useState();

  const [loading, setLoading] = useState(true);

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? "",
      category: data?.category ?? "",
      status: data?.status ?? "",
      content: data?.content ? data.content.join("\n") : "",
      tags: data?.tags ?? [],
      postType: data?.postType ?? "image", // Default to image post type
      imageURL: data?.imageURLs ?? [],
      blogId: data?.blogId ?? "",
    },

    onSubmit: async (values, { resetForm }) => {
      const confirmResult = await MySwal.fire({
        text: `¿Estás seguro de que quieres ${
          mode === "add" ? "agregar" : "editar"
        } este post?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        const content = values.content.split("\n").filter(Boolean);
        const today = new Date();
        const postDate = fsTimestampCreador.fromDate(today);

        const postData = {
          cdate: data?.cdate ?? postDate,
          name: values?.name ?? "",
          content: content,
          category: values?.category ?? "",
          postType: values?.postType,
          videoURL: values.videoURL.includes("youtube")
            ? values.videoURL + "&"
            : values.videoURL,
          tags: values?.tags ?? [],
          status: adminData?.premiumStatus
            ? adminData.tier === "elite"
              ? "accepted"
              : adminData.tier === "premium"
              ? "pending"
              : "pending"
            : "pending",
          sponsorName: sponsor.name,
          sponsorId: sponsor.sponsorId,
          sponsorImageURL: sponsor.logo,
        };

        try {
          if (mode === "add") {
            const newPost = await addBlogPost(values.blogId, postData);

            if (imageUploads.length > 0) {
              //imageUploads

              await createPostImages(imageUploads, values.blogId, newPost); //imageUploads
            }
          } else {
            if (postId) {
              const shouldMovePost = data.blogId !== values.blogId;
              await editBlogPost(
                values.blogId,
                data.blogId,
                postId,
                postData,
                imageUploads,
                shouldMovePost
              );
            }
          }

          MySwal.fire("Cambios Guardados", "", "success");
          resetForm();
          setImageUploads([]);
          setImages([]);
          onRefresh();
          onClose();
          loadingSwal.close();
        } catch (error) {
          resetForm();
          setImageUploads([]);
          setImages([]);
          MySwal.fire("Error", "Hubo un error al editar el post", "error");
          console.error(error);
          onClose();
          loadingSwal.close();
        }
      }
    },
  });
  useEffect(() => {
    formik.setValues({
      name: data?.name ?? "",
      category: data?.category ?? "",
      status: data?.status ?? "",
      content: data?.content ? data.content.join("\n") : "",
      tags: data?.tags ?? [],
      postType: data?.postType ?? "image", // Default to image post type
      videoURL: "",
      imageURL: data?.imageURLs ?? "",
      blogId: data?.blogId ?? "",
    });
    const copyArr = data?.imageURLs?.slice() || [];
    setImages(copyArr);
    setImageUploads(copyArr);
    setIsLoadingImg(false);
    if (mode === "edit") {
      setPostId(data.id);
    }
  }, [data]);
  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  if (loading) {
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}></DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}>Post</DialogTitle>
      <IconButton
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          right: 20,
          top: 12,
          color: "white",
        }}
        onClick={handleClose}
      >
        <MdClose size={24} />
      </IconButton>
      <DialogContent>
        <Box sx={styles.formContainer}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
              <Grid container sx={styles.summaryCard}>
                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  rowGap={4}
                  mb={2}
                  mt={2}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        select
                        label="Tipo de post"
                        name="postType"
                        value={formik.values.postType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="image">Imagen</MenuItem>
                        <MenuItem value="video">Video</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5.5}>
                    <TextField
                      fullWidth
                      label="Nombre"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5.5}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        select
                        label="Categoría"
                        name="category"
                        value={formik.values.category}
                        onChange={(event) => {
                          const selectedCategory = event.target.value;
                          const selectedBlog = blogs.find(
                            (blog) => blog.name === selectedCategory
                          );
                          const selectedBlogId = selectedBlog
                            ? selectedBlog.id
                            : "";
                          console.log(selectedBlog);
                          formik.setFieldValue("category", selectedCategory);
                          formik.setFieldValue("blogId", selectedBlogId); // Update blogId field
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.category &&
                          Boolean(formik.errors.category)
                        }
                        helperText={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        {blogs.map((blog) => (
                          <MenuItem key={blog.id} value={blog.name}>
                            {blog.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xxs={12} xs={12}>
                    <TagsInput
                      value={formik.values.tags}
                      onChange={(tags) => formik.setFieldValue("tags", tags)}
                      name="tags"
                      placeHolder="Introduzca nuevas etiquetas"
                    />
                  </Grid>

                  <Grid item xxs={12} xs={12}>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={10}
                      aria-label="content"
                      placeholder="Contenido"
                      name="content"
                      value={formik.values.content}
                      onChange={formik.handleChange}
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        resize: "vertical",
                        //lineHeight: "40px",
                        borderRadius: "0.5rem",
                        border: "1px solid #cccccc",
                        padding: "12px 12px",
                        fontFamily: "'IBM Plex Sans', sans-serif",
                        fontWeight: "700",
                        fontSize: "17px",
                      }}
                    />
                    {formik.touched.content && formik.errors.content ? (
                      <Typography color="error" variant="body2">
                        {formik.errors.content}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                {formik.values.postType === "image" ? (
                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    xs={12}
                    sm={12}
                    md={12}
                    my={2}
                  >
                    <UploadImageMultiple
                      imageUpload={imageUploads}
                      setImageUpload={setImageUploads}
                      setImages={setImages}
                      images={images}
                      loading={isLoadingImg}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    xs={12}
                    sm={12}
                    md={12}
                    my={2}
                  >
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label="URL VIDEO"
                        name="videoURL"
                        value={formik.values.videoURL}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.videoURL &&
                          Boolean(formik.errors.videoURL)
                        }
                        helperText={
                          formik.touched.videoURL && formik.errors.videoURL
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box mt={2} display="flex" justifyContent="end" paddingRight="20px">
              <ASbutton
                onClick={formik.handleSubmit}
                backgroundColor={theme.palette.primary.main}
                color={"white"}
                buttonText={"Guardar"}
              />
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>{/* Your actions or buttons here */}</DialogActions>
    </Dialog>
  );
};
