import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import loginCover from "./../../assets/logincover.png";
import logo from "./../../assets/logo.png";

import authErrors from "../../firebase/auth-error-codes";
import { loginUser } from "../../store/actions/auth";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Colors from "../../utils/Colors";

const defaultFormFields = {
  email: "",
  password: "",
};

const LogInForm = ({ setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error: authenticationError, isAdmin } = useSelector(
    (state) => state.auth
  );
  const theme = useTheme();
  const validate = Yup.object({
    email: Yup.string().email("Email no valido").required("Email es requerido"),
    password: Yup.string()
      .min(6, "Contraseña debe contener al menos 6 caracteres")
      .required("Contraseña requerida"),
  });
  const navigateTo = (route) => {
    navigate(route);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: validate,
    onSubmit: (values, { validateForm, setFieldTouched }) => {
      customHandleSubmit(values.email, values.password);
    },
  });

  const customHandleSubmit = async (email, password) => {
    dispatch(loginUser(email, password));
  };

  return (
    <Container>
      <Grid container>
        <Grid item sm={6}>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              [theme.breakpoints.down("sm")]: {
                margin: "1em auto 2em auto",
                width: "50%",
                height: "auto",
              },
            }}
          >
            <img
              src={loginCover}
              alt="Home"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "65%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
              }}
              mb={2}
            >
              <img
                src={logo}
                alt="Home"
                style={{ width: "75%", height: "auto" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
              }}
              mb={2}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Hola 👋
              </Typography>
              <Typography variant="h7">Por favor ingresas tus datos</Typography>
            </Box>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Email"
                type="email"
                id="email"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Password"
                type="password"
                id="password"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id="rememberMe"
                    onChange={formik.handleChange}
                    checked={formik.values.rememberMe}
                  />
                }
                label="Recordar Contraseña"
              />
              <Typography
                variant="body1"
                color={theme.palette.primary.main}
                sx={{ cursor: "pointer" }}
                onClick={() => navigateTo("/auth/forgotpassword")}
              >
                Olvidaste la contraseña?
              </Typography>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
            >
              Iniciar Sesión
            </Button>
          </form>

          {!isAdmin && (
            <p className="text-danger mt-3">
              Cuenta de Usuario no corresponde a una asociación
            </p>
          )}
          <p className="text-danger mt-3">
            {
              authErrors[
                authenticationError?.code
                  ? authenticationError?.code
                  : "no-error"
              ].msg
            }
          </p>
          <Box
            sx={{
              backgroundColor: "transparent",
              px: 2,
              py: 1,
              borderRadius: "18px",
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            <Typography
              sx={{
                color: theme.palette.primary.secondary,
                fontWeight: "bold",
              }}
            >
              Quieres ser sponsor?
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LogInForm;
