import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
} from "@mui/material";
import EditBranch from "./EditBranch"; // Import your EditBranch component
import { createBranch, getBranches } from "../../store/actions/sponsor";
import { useTheme } from "@emotion/react";

function EditBranches({ sponsorId, open, onClose, branches, setBranches }) {
  const theme = useTheme();
  const [loading, setloading] = useState(false);

  const getSponsorBranches = async () => {
    const data = await getBranches(sponsorId);

    setBranches(data);
  };

  getSponsorBranches();

  const handleAddBranch = async () => {
    const newBranchData = {
      city: "",
      location: "",
      province: "San José",
      canton: "",
      district: "",
      country: "",
      latitude: "",
      longitude: "",
      geopoint: "",
      cdate: new Date(),
    };
    await createBranch(sponsorId, newBranchData);
    getSponsorBranches();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: "100%",

            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle>
          Sucursales
          <Button onClick={handleAddBranch} color="primary">
            Crear Sucursal
          </Button>
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: "65vh",
            height: "65vh",
          }}
        >
          {branches.map((branch, index) => (
            <EditBranch
              sponsorId={sponsorId}
              key={index}
              data={branch}
              index={index}
              getSponsorBranches={getSponsorBranches}
              theme={theme}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditBranches;
