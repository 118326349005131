import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ASbutton } from "../buttons/ASbutton";

const MySwal = withReactContent(Swal);

export const BlogsTable = ({
  items,
  isDataAvailable,
  loading,
  handleRefresh,
  handleChange,
  value,
}) => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [usersFromDb, setUsersFromDB] = useState([]);
  const isFirstUse = useRef(true);

  const [selectedItem, setSelectedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const onClose = () => {
    setEditModalOpen(false);

    setSelectedItem();
  };
  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (isDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!isDataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, isDataAvailable]
  );

  const handleViewPosts = (blogId) => {
    navigate(`${blogId}`);
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <img
              src={value}
              alt="Banner"
              style={{
                width: "100px",
                height: "50px",
                borderRadius: "4px",
                objectFit: "contain",
              }}
            />
          ) : (
            "N/A"
          );
        },
        filter: false,
      },
    },
    {
      name: "viewBlog",
      label: "Posts",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const selectedBlog = items.find((item) => item.id === rowData[0]);

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <ASbutton
                buttonText={"Ver posts"}
                onClick={() => handleViewPosts(selectedBlog.id)}
              ></ASbutton>
            </Box>
          );
        },
        filter: false,
        sort: false,
      },
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value) => {
    //       return value === "redeemed"
    //         ? "Redimido"
    //         : value === "pending"
    //         ? "Pendiente"
    //         : value === "refunded"
    //         ? "Reembolsado"
    //         : value === "refundRequest"
    //         ? "Pendiente Reembolso"
    //         : "--";
    //     },
    //     filter: true,
    //   },
    // },
    // {
    //   name: "userRef",
    //   label: "Referencia",
    //   options: {
    //     display: false,
    //     filter: false,
    //   },
    // },
  ];
  const CustomTitle = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      ></Box>
    );
  };
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10,
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
    // onTableChange: () => setUsersFromDB(codes),
    // sortOrder: {
    //   name: "status",
    //   direction: "asc",
    // },
    // sortCompare: (order) => (a, b) => {
    //   const isAsc = order === "asc";
    //   const statusOrder = ["active", "pending", "deactivated"];

    //   // Replace "status" with the column name you want to sort by
    //   const column = "status";

    //   const aValue = a.data[column];
    //   const bValue = b.data[column];

    //   const aIndex = statusOrder.indexOf(aValue);
    //   const bIndex = statusOrder.indexOf(bValue);

    //   if (aIndex === bIndex) {
    //     // If statuses are the same, use default comparison
    //     return isAsc
    //       ? aValue.localeCompare(bValue)
    //       : bValue.localeCompare(aValue);
    //   } else {
    //     // Compare based on statusOrder array
    //     return isAsc ? aIndex - bIndex : bIndex - aIndex;
    //   }
    // },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box mt={4} sx={{ width: "100%" }}>
      {/* <div className="refresh-container">
                <Button className="refresh-button">
                  <IoRefreshOutline size={25} onClick={handleRefresh} />
                </Button>
              </div> */}
      <MUIDataTable
        title={CustomTitle()}
        data={items}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
    </Box>
  );
};
