import {
  SET_EXCHANGES,
  SET_DISCOUNTS,
  SET_SPONSOR,
  LOGOUT_SPONSOR,
  SET_REDEEMS,
} from "../actions/sponsor";

const initialState = {
  sponsor: null,

  exchanges: [],
  discounts: [],
  redeems: [],
  blogs: [],
};

export const sponsorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPONSOR:
      return {
        ...state,
        sponsor: action.payload.sponsor,
        // displayName: action.payload.displayName,
      };

    case SET_EXCHANGES:
      return {
        ...state,
        exchanges: action.exchanges,
      };
    case SET_DISCOUNTS:
      return {
        ...state,
        discounts: action.discounts,
      };
    case SET_REDEEMS:
      return {
        ...state,
        redeems: action.redeems,
      };
    case LOGOUT_SPONSOR:
      return initialState;

    default:
      return state;
  }
};
