import React, { useState } from "react";
import { styles } from "../../styles/exchangeCardStyles";
import { Box, Button, Paper, Typography } from "@mui/material";
import { ASbutton } from "../buttons/ASbutton";
import Colors from "../../styles/Colors";
import { ExchangeModal } from "../modals/ExchangeModal";

export const ExchangeCard = ({
  item,
  sponsor,

  handleSponsor,

  index,
  onRefresh,
  isLoading,
}) => {
  const isEven = index % 2 === 0;

  const cardColor = isEven ? Colors.accent : Colors.softCyan;
  const [imgLoading, setImgLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Paper
      sx={[
        styles.cardWrapper,
        isEven
          ? { backgroundColor: cardColor }
          : { backgroundColor: cardColor },
      ]}
      elevation={3}
      key={index}
    >
      {/* <CouponSVG style={styles.cardContainer} /> */}

      <Box sx={styles.content}>
        <Box sx={styles.price}>
          <Box sx={styles.priceNumber}>
            <Typography sx={[styles.priceNumberText, { color: cardColor }]}>
              {item.coinPrice}
            </Typography>
            {/* <CustomIcon
              name={"icono_sumar-coins"}
              size={
                Platform.OS === "ios"
                  ? windowSize.height < 750
                    ? 18
                    : 16
                  : windowSize.height < 750
                  ? 12
                  : 14
              }
              color={isEven ? Colors.accent : Colors.softCyan}
              sx={styles.priceIcon}
            /> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "150px",
          }}
        >
          {imgLoading ? (
            <Box sx={styles.imageContainer}>
              <Button onClick={() => handleSponsor} sx={styles.imageButton}>
                <img
                  src={require("../../assets/darkCoin.gif")}
                  alt="Product"
                  sx={styles.image}
                />
              </Button>
            </Box>
          ) : (
            <Box sx={styles.imageContainer}>
              <img src={sponsor.logo} alt="Product" style={styles.image} />
            </Box>
          )}
        </Box>
        <Box sx={styles.product}>
          <Typography sx={styles.cardTitle} allowFontScaling={false}>
            {item.name}
          </Typography>

          <Typography sx={styles.cardSubTitle} allowFontScaling={false}>
            {sponsor?.name}
          </Typography>
        </Box>

        <Box sx={styles.buttonContainer}>
          <ASbutton
            backgroundColor={isEven ? Colors.softCyan : Colors.accent}
            buttonText={"Editar"}
            width={"135px"}
            height={45}
            borderRadius={"12px"}
            onClick={() => handleOpenModal()}
            color={Colors.light}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <ExchangeModal
        open={open}
        item={item}
        onClose={onClose}
        onRefresh={onRefresh}
        sponsor={sponsor}
      />
    </Paper>
  );
};
