import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  getSponsorData,
  getSponsorDiscounts,
} from "../../store/actions/sponsor";

import { DiscountCard } from "../../components/products/DiscountCard";
import { ASbutton } from "../../components/buttons/ASbutton";
import { AddDiscountModal } from "../../components/modals/AddDiscountModal";
import { store } from "../../store/store";

const MySwal = withReactContent(Swal);

export const DiscountsPage = () => {
  const { idToken } = useSelector((state) => state.auth);
  const { adminData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { discounts } = useSelector((state) => state.sponsor);
  const theme = useTheme();

  const isFirstUse = useRef(true);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0

  const [loading, setLoading] = useState(false);
  //IMAGE
  const { sponsor } = useSelector((state) => state.sponsor);
  const sponsorId = adminData?.sponsorId;
  const [sponsorPromos, setSponsorPromos] = useState({});
  const [open, setOpen] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(true);

  const getProductsFromDB = useCallback(async () => {
    try {
      const currentTimestamp = new Date(); // Get the current date and time

      dispatch(getSponsorDiscounts(sponsorId));
      dispatch(getSponsorData(sponsorId));
      // console.log(
      //   discounts.map((discount) => {
      //     const dueDate = discount.dueDate.toDate(); // Convert Firestore Timestamp to JavaScript Date
      //     const currentTimestamp = new Date(); // Get the current date and time

      //     console.log("dueDate:", dueDate);
      //     console.log("currentTimestamp:", currentTimestamp);

      //     return {
      //       ...discount,
      //       dueDate: dueDate,
      //     };
      //   })
      // );

      setSponsorPromos(discounts);
      const active = discounts.filter((r) => r.status === "active");
      const pending = discounts.filter((r) => r.status === "pending");
      const expired = discounts.filter(
        (r) => r.dueDate.toDate() < currentTimestamp
      );
      const rejected = discounts.filter((r) => r.status === "rejected");

      setSponsorPromos({
        all: discounts,
        active: active,
        pending: pending,
        expired: expired,
        rejected: rejected,
      });
    } catch (error) {
      console.log(error);
    }
  }, [sponsorId, dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getProductsFromDB();
      isFirstUse.current = false;
    } else if (discounts?.length > 0) {
      const currentTimestamp = new Date();
      const active = discounts.filter(
        (r) => r.status === "active" && r.dueDate.toDate() >= currentTimestamp
      );
      const pending = discounts.filter(
        (r) => r.status === "pending" && r.dueDate.toDate() >= currentTimestamp
      );
      const expired = discounts.filter(
        (r) => r.dueDate.toDate() < currentTimestamp
      );
      const rejected = discounts.filter((r) => r.status === "rejected");
      console.log(expired);
      // dispatch(getPromotions(sponsorId));
      setSponsorPromos({
        all: discounts,
        active: active,
        pending: pending,
        expired: expired,
        rejected: rejected,
      });

      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [discounts, getProductsFromDB]);

  const handleRefresh = () => {
    setLoading(true);
    getProductsFromDB();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const steps = ["Todos", "Activos", "Pendientes", "Expirados", "Rechazados"];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const onClose = () => {
    setOpen(false);
  };

  const discountsGrid = (type) => {
    if (loading) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
          }}
        >
          <CircularProgress />
        </Grid>
      );
    }
    if (!dataAvailable) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
          }}
        >
          "No hay Descuentos Disponibles"
        </Grid>
      );
    }
    return (
      <>
        <Grid
          container
          mt={4}
          justifyContent={
            sponsorPromos?.[type]?.length > 2 ? "space-between" : "flex-start"
          }
          columnGap={{ xs: 1, sm: 1, md: 3 }}
          rowGap={{ xxs: 2, xs: 2, sm: 2, md: 4 }}
        >
          {sponsorPromos?.[type]?.map((item, index) => (
            <Grid
              item
              container
              // justifyContent={{
              //   xs: "center",
              //   sm: "center",
              //   md: "center",
              //   lg: "center",
              //   xl: "center",
              // }}
              md={5.5}
              lg={3.5}
              // xl={3}
              sm={5.5}
              xs={12}
              xxs={12}
              key={item.id}
              sx={{
                width: "100%",
                borderRadius: "18px",
                boxShadow:
                  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);",
              }}
            >
              <DiscountCard
                item={item}
                index={index}
                sponsor={sponsor}
                onRefresh={handleRefresh}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={[styles.formCard, { position: "relative" }]}>
        <Box mt={4} ml={4}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Descuentos
          </Typography>
        </Box>
        <Box
          mt={4}
          ml={4}
          sx={{
            position: { xxs: "relative", sm: "absolute" },
            right: 20,
            top: -20,
          }}
        >
          <ASbutton
            buttonText={"Agregar Descuento"}
            onClick={() => setOpen(true)}
          />
        </Box>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>

        <Box mb={6}>
          {activeTab === 0 && discountsGrid("all")}
          {activeTab === 1 && discountsGrid("active")}
          {activeTab === 2 && discountsGrid("pending")}
          {activeTab === 3 && discountsGrid("expired")}
          {activeTab === 4 && discountsGrid("rejected")}
        </Box>
      </Box>
      <AddDiscountModal
        open={open}
        onClose={onClose}
        sponsor={sponsor}
        onRefresh={handleRefresh}
      />
    </Container>
  );
};
