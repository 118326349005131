import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import firebase from "firebase/compat/app";
import "firebase/firestore";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";
import { countryList } from "../../utils/sponsorData";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { deleteBranch, editBranches } from "../../store/actions/sponsor";
import Colors from "../../utils/Colors";

const MySwal = withReactContent(Swal);

const EditBranch = ({ data, sponsorId, index, getSponsorBranches, theme }) => {
  const [expanded, setExpanded] = useState(false);
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const validationSchema = Yup.object().shape({
    city: Yup.string().required("Campo Requerido"),
    location: Yup.string().required("Campo Requerido"),
    province: Yup.string().required("Campo Requerido"),
    canton: Yup.string().required("Campo Requerido"),
    district: Yup.string().required("Campo Requerido"),
    latitude: Yup.number()
      .required("Campo Requerido")
      .typeError("Debe ser un numero"),
    longitude: Yup.number()
      .required("Campo Requerido")
      .typeError("Debe ser un numero"),
  });

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      city: data.city ?? "",
      location: data.location ?? "",
      province: data.province ?? "San José",
      district: data.district ?? "",
      countries: data.country ?? "CR",
      latitude: data.latitude ?? "",
      longitude: data.longitude ?? "",
      canton: data.canton ?? "",
      district: data.district ?? "",
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      city: data.city ?? "",
      location: data.location ?? "",
      province: data.province ?? "San José",
      district: data.district ?? "",
      countries: data.country ?? "CR",
      latitude: data.latitude ?? "",
      longitude: data.longitude ?? "",
      canton: data.canton ?? "",
      district: data.district ?? "",
      geopoint: data.geopoint ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const branchData = {
        city: values.city,
        location: values.location,
        province: values.province,
        country: values.countries ?? "Costa Rica",
        canton: values.canton,
        district: values.district,
        geopoint: new firebase.firestore.GeoPoint(
          parseFloat(values.latitude),
          parseFloat(values.longitude)
        ),
        latitude: values.latitude ?? "",
        longitude: values.longitude ?? "",
      };
      const confirmResult = await MySwal.fire({
        title: "Crear sponsor",
        text: "¿Estás seguro de que deseas editar esta sucursal?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      console.log("values:", values);
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });

        try {
          await editBranches(sponsorId, branchData, data.id);
          setHasUnsavedChanges(false);
          loadingSwal.close();
          MySwal.fire("Sucursal editada!", "", "success");
        } catch (error) {
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al intentar guardar", "error");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredCantons(filterItems);
    }
  }, [formik.values.province, formik.values.canton, formik.values.district]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton, formik.values.district]);

  const handleRemove = () => {
    MySwal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta sucursal?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          deleteBranch(sponsorId, data.id);
          getSponsorBranches();
          MySwal.fire("Sucursal eliminada!", "", "success");
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al eliminar", "error");
          console.log(error);
        }
      }
    });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Accordion
          sx={{
            backgroundColor: Colors.light,
            boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <AccordionSummary sx={{ mt: 1 }}>{`Sucursal ${
            index + 1
          }`}</AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xxs={12} xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="countries">Pais</InputLabel>
                  <Select
                    onChange={formik.handleChange}
                    name={`countries`}
                    value={formik.values.countries}
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {countryList.map((country) => (
                      <MenuItem value={country.name} key={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="province">Provincia</InputLabel>
                  <Select
                    name={`province`}
                    value={formik.values.province}
                    onChange={(e) =>
                      formik.setFieldValue("province", e.target.value)
                    }
                  >
                    {provinces.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="canton">Cantón</InputLabel>
                  <Select
                    name={`canton`}
                    value={formik.values.canton}
                    onChange={formik.handleChange}
                  >
                    {filteredCantons.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="district">Distrito</InputLabel>
                  <Select
                    name={`district`}
                    value={formik.values.district}
                    onChange={formik.handleChange}
                  >
                    {filteredDistricts.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <TextField
                  fullWidth
                  label={"Ciudad"}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  type="text"
                  name={`city`}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <TextField
                  fullWidth
                  label={"Ubicación"}
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  type="text"
                  name={`location`}
                  placeholder={"https://googlemaps"}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <TextField
                  fullWidth
                  label={"Latitud"}
                  onChange={formik.handleChange}
                  value={formik.values.latitude}
                  type="text"
                  name={`latitude`}
                  placeholder={"9.00"}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xxs={12} xs={12} md={6}>
                <TextField
                  fullWidth
                  label={"Longitud"}
                  onChange={formik.handleChange}
                  value={formik.values.longitude}
                  type="text"
                  name={`longitude`}
                  placeholder={"-84.00"}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>
            <div className="text-center mt-2">
              <Button
                type="submit"
                className="btn btn-primary rounded-pill me-4"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
              >
                Guardar
              </Button>
              <Button
                className="btn btn-danger rounded-pill "
                onClick={handleRemove}
              >
                Eliminar Sucursal
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
        {hasUnsavedChanges && ( // Display unsaved changes indicator if changes are detected
          <div variant="warning">hay cambios sin guardar.</div>
        )}
      </form>
    </>
  );
};

export default EditBranch;
