import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Md10K } from "react-icons/md";
import { styles } from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getRedeemsFromSponsor,
  getSponsorData,
  getSponsorDiscounts,
  getSponsorExchanges,
} from "../../store/actions/sponsor";

export const InfoCard = ({ item, theme, index }) => {
  const dispatch = useDispatch();
  const options = { weekday: "long", day: "numeric", month: "long" };
  const formattedDate = new Date().toLocaleDateString("es-CR", options);
  const { adminData } = useSelector((state) => state.auth);
  const { redeems, exchanges, discounts } = useSelector(
    (state) => state.sponsor
  );
  const sponsorId = adminData?.sponsorId;
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const isFirstUse = useRef(true);
  const [isDataAvailable, setDataAvailable] = useState(true);
  const getProductsFromDB = useCallback(async () => {
    try {
      dispatch(getRedeemsFromSponsor(sponsorId));
      dispatch(getSponsorExchanges(sponsorId));
      dispatch(getSponsorDiscounts(sponsorId));
      const refundedRequests = redeems.filter(
        (r) => r.status === "refundRequest"
      );
      setStats({
        redeems: redeems,
        exchanges: exchanges,
        discounts: discounts,
        refunds: refundedRequests,
      });
      dispatch(getSponsorData(sponsorId));
    } catch (error) {
      console.log(error);
    }
  }, [sponsorId, dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getProductsFromDB();
      isFirstUse.current = false;
    } else if (stats) {
      const refundedRequests = redeems.filter(
        (r) => r.status === "refundRequest"
      );
      setStats({
        redeems: redeems,
        exchanges: exchanges,
        discounts: discounts,
        refunds: refundedRequests,
      });
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [redeems, getProductsFromDB]);

  const renderData = () => {
    if (index === 0) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          {stats.exchanges ? stats.exchanges.length : 0}
        </Typography>
      );
    }
    if (index === 1) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          {stats.discounts ? stats.discounts.length : 0}
        </Typography>
      );
    }
    if (index === 2) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          {stats.redeems ? stats.redeems.length : 0}
        </Typography>
      );
    }
    if (index === 3) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          {stats.refunds ? stats.refunds.length : 0}
        </Typography>
      );
    }
  };

  return (
    <Grid
      item
      xs={5}
      md={5.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "18px",
        borderWidth: "2px !important",
        border: "solid",
        borderColor: "#ECECEE",
        height: "145px",
        paddingRight: "20px",
        [theme.breakpoints.down("md")]: {
          mt: 2,
        },
      }}
    >
      <Box
        sx={[styles.rowB, { justifyContent: "flex-start", flex: 1 }]}
        pt={0.2}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#F8F7FF",
            width: "36px",
            height: "36px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
          }}
          mr={1}
        >
          {item.iconName}

          {/* <Md10K color={theme.palette.primary.main} /> */}
        </Box>
        <Typography
          variant="body1"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.75rem !important",
            },
          }}
        >
          {item.title}{" "}
        </Typography>
      </Box>
      <Box sx={[styles.rowB, { justifyContent: "space-between", flex: 2 }]}>
        {renderData()}
        <Box
          sx={{
            width: "66px",
            height: "32px",

            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#EAF9F4",
            borderRadius: "4px",
          }}
        >
          <Md10K color="#31BF82" />
          <Box>
            <Typography
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: "0.7rem !important",
                },
              }}
            >
              12%
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderWidth: "2px" }} />
      <Box sx={[styles.rowB, { justifyContent: "space-between", flex: 1 }]}>
        <Typography
          variant="body2"
          sx={{
            textTransform: "capitalize",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >{`Update: ${formattedDate}`}</Typography>
      </Box>
    </Grid>
  );
};
