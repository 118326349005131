import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  OutlinedInput,
  Select,
  InputLabel,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant, MdSignalCellular1Bar } from "react-icons/md";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond/dist/filepond.min.css"; // Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import imgDrop from "../../assets/imgDrop.png";
import UploadImage from "../general/UploadImage";
import { categories, sectors } from "../../utils/sponsorData";
import { TagsInput } from "react-tag-input-component";
const typeIdSponsor = [{ name: "Cedula Juridica" }, { name: "RUT" }];
export const EditProfileForm = ({
  handleNext,
  handleRegister,
  data,
  image,
  setImage,
  setImageUpload,
  imageUpload,
  setFileUrl,
  selected,
  setSelected,
  loading,
}) => {
  const theme = useTheme();
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const countryList = [{ name: "Costa Rica" }];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Requerido"),
    sectors: Yup.string(),
    category: Yup.string(),
    typeIdSponsor: Yup.string(),
    businessId: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .required("No es un ID valido"),
    countries: Yup.string(),
    province: Yup.mixed().required("Campo requerido"),
    canton: Yup.mixed().required("Campo requerido"),
    district: Yup.mixed().required("Campo requerido"),
    city: Yup.string().required("Requerido"),
    location: Yup.string().required("Requerido"),
    facebook: Yup.string(),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    whatsApp: Yup.string(),
    phone: Yup.string(),
    adminEmail: Yup.string()
      .email("Formato no valido")
      .typeError("Formato no valido")
      .required("Requerido"),
    geopoint: "",
  });
  useEffect(() => {
    console.log(data.category);
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data.name ?? "",
      category: data.category ?? [],
      email: data.email || "",
      businessName: data.businessName || "",
      businessId: data.businessId ?? "",
      phone: data.phone ?? "",
      instagram: data.instagram ?? "",
      tiktok: data.tiktok ?? "",
      whatsApp: data.whatsApp ?? "",
      facebook: data.facebook ?? "",
      location: data.location ?? "",
      address: data.address ?? "",
      logo: data.logo ?? "",
    });
    setImage(data.logo);
  }, [data]);
  const formik = useFormik({
    initialValues: {
      name: data.name ?? "",
      email: data.email || "",
      category: data.category ?? [],
      businessId: data.businessId ?? "",
      phone: data.phone ?? "",
      instagram: data.instagram ?? "",
      tiktok: data.tiktok ?? "",
      whatsApp: data.whatsApp ?? "",
      facebook: data.facebook ?? "",
      location: data.location ?? "",
      address: data.address ?? "",

      isActive: data.isActive ?? false,
      logo: data.logo ?? "",
    },
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        handleRegister(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFileUpload = (image) => {
    // Handle the uploaded files, e.g., set them in the component's state
    setImage(image);
  };

  //   useEffect(() => {
  //     console.log(formik.values);
  //   }, [formik.values]);

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [formik.values.province]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton]);
  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <UploadImage
          loadUrl={setFileUrl}
          imageUpload={imageUpload}
          setImageUpload={setImageUpload}
          setImage={setImage}
          image={image}
        />
      </Box>
      <form style={{ padding: 30 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nombre Sponsor"
              fullWidth
              variant="outlined"
              name="name"
              value={formik.values.name}
              disabled
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Correo Electrónico"
              fullWidth
              variant="outlined"
              name="email"
              disabled
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Teléfono"
              fullWidth
              variant="outlined"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Cedula Jurídica"
              fullWidth
              variant="outlined"
              disabled
              name="businessId"
              value={formik.values.businessId}
              onChange={formik.handleChange}
              error={
                formik.touched.businessId && Boolean(formik.errors.businessId)
              }
              helperText={formik.touched.businessId && formik.errors.businessId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Dirección"
              fullWidth
              variant="outlined"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xxs={12} xs={12} md={6}>
            <FormControl sx={{ width: "100% !important" }}>
              <InputLabel id="demo-multiple-name-label">Categoría</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                multiple
                value={formik.values.category}
                onChange={formik.handleChange}
                input={<OutlinedInput label="Name" sx={{ width: "100%" }} />}
                MenuProps={MenuProps}
              >
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              //fullWidth
              id="category"
              name="category"
              select
              label="Categoría"
              value={formik.values.category}
              onChange={formik.handleChange}
              error={formik.touched.category && Boolean(formik.errors.category)}
              SelectProps={{
                fullWidth: true,
                multiple: true, // Add this line to enable multi-select
                value: formik.values.category,
                onChange: (event) => {
                  formik.setFieldValue("category", event.target.value);
                },
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                },
                PopperProps: {
                  disablePortal: true,
                  placement: "bottom-start", // Adjust the placement as needed
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSignalCellular1Bar />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {categories.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Tiktok"
              fullWidth
              variant="outlined"
              name="tiktok"
              value={formik.values.tiktok}
              onChange={formik.handleChange}
              error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
              helperText={formik.touched.tiktok && formik.errors.tiktok}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Whatsapp"
              fullWidth
              variant="outlined"
              name="whatsApp"
              value={formik.values.whatsApp}
              onChange={formik.handleChange}
              error={formik.touched.whatsApp && Boolean(formik.errors.whatsApp)}
              helperText={formik.touched.whatsApp && formik.errors.whatsApp}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Facebook"
              fullWidth
              variant="outlined"
              name="facebook"
              value={formik.values.facebook}
              onChange={formik.handleChange}
              error={formik.touched.facebook && Boolean(formik.errors.facebook)}
              helperText={formik.touched.facebook && formik.errors.facebook}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Instagram"
              fullWidth
              variant="outlined"
              name="instagram"
              value={formik.values.instagram}
              onChange={formik.handleChange}
              error={
                formik.touched.instagram && Boolean(formik.errors.instagram)
              }
              helperText={formik.touched.instagram && formik.errors.instagram}
            />
          </Grid>
          <Grid item xs={12} md={12} container justifyContent={"flex-start"}>
            <TagsInput
              value={selected}
              style={{ background: "red" }}
              onChange={setSelected}
              name="tags"
              placeHolder="presionar enter para cada producto..."
              classNames={{
                input: "inputTag",
                tag: "bg-tags",
              }}
            />
          </Grid>
          {/* <Grid item xs={6} mt={2}>
            <FormControl
              component="fieldset"
              sx={{
                marginLeft: "0px !important",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                value="isActive"
                label="Activo"
                labelPlacement="start"
                control={
                  <Checkbox
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>
          </Grid> */}
        </Grid>

        <Box width="100%" display="flex" mt={3}>
          <Box sx={{ marginLeft: "auto", marginRight: { xxs: "auto", sm: 0 } }}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Guardar"}
              disabled={!image}
            />
          </Box>
        </Box>
      </form>
      {/* <Grid
        container
        sx={styles.buttonsContainer}
        //justifyContent={"flex-end"}
        direction="row"
      > */}
      {/* <Grid item>
          <ASbutton
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            disabled={activeStep === 0}
            buttonText={"Cancelar"}
          />
        </Grid> */}
      {/* <Grid item xs={12}>
          <Box ml={2} width="100%">
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Guardar"}
              disabled={!image}
            />
          </Box>
        </Grid> */}
      {/* </Grid> */}
    </Box>
  );
};
