import { SET_BLOGS, SET_POSTS, ADD_POST } from "../actions/blogs";

const initialState = {
  blogs: [],
  posts: [], // Object to store posts by blogId
};

export const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOGS:
      return {
        ...state,
        blogs: action.blogs,
      };
    case SET_POSTS:
      return {
        ...state,
        posts: action.posts, // Update the posts array directly
      };

    case ADD_POST:
      return {
        ...state,
        posts: [...state.posts, action.post],
      };

    default:
      return state;
  }
};
