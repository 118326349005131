import React, { useRef } from "react";
import {
  CardContent,
  CardMedia,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import fallback from "../../assets/imgDrop.png";

const UploadImageMultiple = ({
  setImageUpload,
  imageUpload,
  setImages,
  images,
  loading,
}) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const removeImage = (index) => {
    const updatedImagesUpload = [...imageUpload];
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    updatedImagesUpload.splice(index, 1);
    setImageUpload(updatedImagesUpload);
    setImages(updatedImages.map((file) => URL.createObjectURL(file)));
  };

  const handleClickImage = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  console.log(images, loading);

  // if (loading) {
  //   return <div style={{ background: "yellow" }}>Loading...</div>;
  // }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        elevation: 1,
      }}
    >
      <CardContent>
        {images.map((file, index) => (
          // {imageUpload.map((file, index) => (
          <Box
            key={index}
            sx={{ position: "relative", display: "inline-block" }}
          >
            <IconButton
              size="small"
              onClick={() => removeImage(index)}
              aria-label="Remove Image"
              sx={{
                position: "absolute",
                right: -8,
                top: -8,
                backgroundColor: theme.palette.primary.main,
                zIndex: 1,
              }}
            >
              <CloseIcon fontSize="small" sx={{ color: "white" }} />
            </IconButton>
            {loading ? (
              <div style={{ background: "yellow" }}>Loading...</div>
            ) : (
              <label htmlFor={`file-input-${index}`}>
                <CardMedia
                  component="img"
                  alt="Uploaded Image"
                  //image={URL.createObjectURL(file)}
                  image={file}
                  sx={{
                    objectFit: "cover",
                    width: "100px",
                    height: "100px",
                    cursor: "pointer",
                    borderRadius: "18px",
                    marginRight: "10px",
                  }}
                  onClick={handleClickImage}
                />
              </label>
            )}
          </Box>
        ))}
        <label htmlFor="file-input">
          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={fallback}
            sx={{
              objectFit: "cover",
              width: "100px",
              height: "100px",
              cursor: "pointer",
              borderRadius: "18px",
            }}
            onClick={handleClickImage}
          />
        </label>
        <input
          ref={fileInputRef}
          type="file"
          name="images"
          id="file-input"
          style={{ display: "none" }}
          multiple
          onChange={(event) => {
            const files = Array.from(event.target.files);
            setImageUpload(files);
            setImages(files.map((file) => URL.createObjectURL(file)));
          }}
        />
      </CardContent>
    </Box>
  );
};

export default UploadImageMultiple;
