import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant, MdSignalCellular1Bar } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond/dist/filepond.min.css"; // Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import UploadImage from "../general/UploadImage";
import { categories } from "../../utils/sponsorData";
import {
  createSponsorImage,
  createSponsorImagePreview,
  sendForm,
  updateSponsor,
} from "../../store/actions/sponsor";
import { TagsInput } from "react-tag-input-component";

const MySwal = withReactContent(Swal);
export const RegisterModal = ({ open, onClose }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const typeIdSponsor = [{ name: "Cedula Juridica" }, { name: "RUT" }];
  const validationSchema = Yup.object().shape({
    businessId: Yup.string().nullable(),
    name: Yup.string().required("Name is required"),
    category: Yup.array().of(Yup.string()).min(1, "Category is required"),
    email: Yup.string().email("Invalid email format").nullable(),
    phone: Yup.string().matches(/^\d+$/, "Invalid phone number").nullable(),
    // Add more validation rules as needed
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      businessName: "",

      businessId: "",
      phone: "",
      instagram: "",
      tiktok: "",
      whatsApp: "",
      facebook: "",
      location: "",
      address: "",
      latitude: "",
      longitude: "",
      // imageURL: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        handleRegister(values);
      } catch (error) {
        console.log(error);
      }
    },
  });
  const handleRegister = async (values) => {
    const sponsorProducts = selected;
    const updatedData = {
      businessId: values.businessId ?? "",
      name: values.name ?? "",
      category: [values.category] ?? [],
      country: values.countries ?? "",
      facebook: values.facebook ?? "",
      instagram: values.instagram ?? "",
      tiktok: values.tiktok ?? "",
      whatsApp: values.whatsApp,
      cdate: new Date(),
      products: sponsorProducts ?? "",
      email: values.email ?? "",
      phone: values.phone ?? "",
      isActive: false,
    };

    const confirmResult = await MySwal.fire({
      title: "Confirmar envio",
      text: "¿Estás seguro de que deseas enviar tu información?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      try {
        await sendForm(updatedData);
        // if (imageUpload !== "") {
        //   createSponsorImagePreview(imageUpload, requestId);
        // }

        handleRefresh();

        MySwal.fire("`Enviado`!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al editar la asociación", "error");
      }

      handleRefresh();
      setLoading(false);
    }
  };
  const handleRefresh = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleFileUpload = (image) => {
    // Handle the uploaded files, e.g., set them in the component's state
    setImage(image);
  };

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",

          overflowX: "hidden",
        },
      }}
    >
      <form>
        <DialogTitle color={theme.palette.primary.main}>
          Solicitud de Sponsor
        </DialogTitle>
        <DialogContent>
          <Box sx={[styles.requestCard]}>
            <Grid
              container
              spacing={2}
              sx={styles.requestSummaryCard}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre Sponsor"
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Correo Electrónico"
                  fullWidth
                  variant="outlined"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Teléfono"
                  fullWidth
                  variant="outlined"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Cedula Jurídica"
                  fullWidth
                  variant="outlined"
                  name="businessId"
                  value={formik.values.businessId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.businessId &&
                    Boolean(formik.errors.businessId)
                  }
                  helperText={
                    formik.touched.businessId && formik.errors.businessId
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Dirección"
                  fullWidth
                  variant="outlined"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  id="category"
                  name="category"
                  select
                  label="Categoría"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                    },
                    PopperProps: {
                      disablePortal: true,
                      placement: "bottom-start", // Adjust the placement as needed
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdSignalCellular1Bar />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                >
                  {categories.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Tiktok"
                  fullWidth
                  variant="outlined"
                  name="tiktok"
                  value={formik.values.tiktok}
                  onChange={formik.handleChange}
                  error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
                  helperText={formik.touched.tiktok && formik.errors.tiktok}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Whatsapp"
                  fullWidth
                  variant="outlined"
                  name="whatsApp"
                  value={formik.values.whatsApp}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.whatsApp && Boolean(formik.errors.whatsApp)
                  }
                  helperText={formik.touched.whatsApp && formik.errors.whatsApp}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Facebook"
                  fullWidth
                  variant="outlined"
                  name="facebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.facebook && Boolean(formik.errors.facebook)
                  }
                  helperText={formik.touched.facebook && formik.errors.facebook}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Instagram"
                  fullWidth
                  variant="outlined"
                  name="instagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.instagram && Boolean(formik.errors.instagram)
                  }
                  helperText={
                    formik.touched.instagram && formik.errors.instagram
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                container
                justifyContent={"flex-start"}
                mb={2}
              >
                <TagsInput
                  value={selected}
                  style={{ background: "red" }}
                  onChange={setSelected}
                  name="tags"
                  placeHolder="Cafe, reposteria, gaseosas..."
                  classNames={{
                    input: "inputTag",
                    tag: "bg-tags",
                  }}
                />
              </Grid>

              {/* <Grid
                item
                xs={12}
                md={12}
                container
                justifyContent={"center"}
                sx={{ position: "relative" }}
              >
                <Typography sx={{ position: "absolute", left: 50, top: 20 }}>
                  Logo
                </Typography>
                <UploadImage
                  loadUrl={setFileUrl}
                  imageUpload={imageUpload}
                  setImageUpload={setImageUpload}
                  setImage={setImage}
                  image={image}
                />
              </Grid> */}
              {/* <Grid item xs={6} mt={2}>
            <FormControl
              component="fieldset"
              sx={{
                marginLeft: "0px !important",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                value="isActive"
                label="Activo"
                labelPlacement="start"
                control={
                  <Checkbox
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>
          </Grid> */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {" "}
          <DialogActions>
            <Grid
              container
              sx={styles.buttonsContainer}
              justifyContent={"flex-end"}
              direction="row"
            >
              {/* <Grid item>
          <ASbutton
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            disabled={activeStep === 0}
            buttonText={"Cancelar"}
          />
        </Grid> */}
              <Grid item>
                <Box ml={2}>
                  <ASbutton
                    onClick={formik.handleSubmit}
                    backgroundColor={theme.palette.primary.main}
                    color={"white"}
                    buttonText={"Enviar Solicitud"}
                    disabled={!formik.isValid}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogActions>
      </form>
    </Dialog>
  );
};
