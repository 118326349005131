import theme from "../styles/asTheme";
import {
  MdGroups2,
  MdShoppingBag,
  MdNoteAdd,
  MdStickyNote2,
} from "react-icons/md";

export const InfoCardData = [
  {
    id: "1",
    title: "Mis Canjes",
    iconName: <MdGroups2 color={theme.palette.primary.main} />,
  },
  {
    id: "2",
    title: "Mis Descuentos",
    iconName: <MdShoppingBag color={theme.palette.primary.main} />,
  },
  {
    id: "3",
    title: "Redención de Canjes",
    iconName: <MdNoteAdd color={theme.palette.primary.main} />,
  },
  {
    id: "4",
    title: "Reembolsos",
    iconName: <MdStickyNote2 color={theme.palette.primary.main} />,
  },
];
