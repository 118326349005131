import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  createAssociationImage,
  getAssociationData,
  updateB2b,
} from "../../store/actions/association";
import { EditProfileForm } from "../../components/profile/EditProfileForm";
import ActivePlans from "../../components/profile/ActivePlans";
import {
  getPromotions,
  getSponsorData,
  getSponsorDiscounts,
  getSponsorExchanges,
} from "../../store/actions/sponsor";
import { ExchangeCard } from "../../components/products/ExchangeCard";
import { DiscountCard } from "../../components/products/DiscountCard";

const MySwal = withReactContent(Swal);

export const ProductsPage = () => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { exchanges, discounts } = useSelector((state) => state.sponsor);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState("");
  const isFirstUse = useRef(true);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const { adminData } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  //IMAGE
  const { sponsor } = useSelector((state) => state.sponsor);
  const sponsorId = adminData?.sponsorId;
  const [sponsorExchanges, setSponsorExchanges] = useState({});
  const [sponsorPromos, setSponsorPromos] = useState({});
  const [dataAvailable, setDataAvailable] = useState(false);

  const getProductsFromDB = useCallback(async () => {
    try {
      dispatch(getSponsorExchanges(sponsorId));
      dispatch(getSponsorDiscounts(sponsorId));
      dispatch(getSponsorData(sponsorId));
      // dispatch(getPromotions(sponsorId));
      setSponsorExchanges(exchanges);
      setSponsorPromos(discounts);
    } catch (error) {
      console.log(error);
    }
  }, [sponsorId, dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getProductsFromDB();
      isFirstUse.current = false;
    } else if (exchanges?.length > 0 && discounts?.length > 0) {
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [exchanges, discounts, getProductsFromDB]);

  const handleRefresh = () => {
    setLoading(true);
    getProductsFromDB();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const steps = ["Canjes", "Descuentos"];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const exchangesGrid = () => {
    return (
      <>
        <Grid
          container
          mt={4}
          justifyContent={exchanges.length > 4 ? "space-between" : "flex-start"}
          columnGap={{ xs: 1, sm: 1, md: 2 }}
        >
          {exchanges?.map((item, index) => (
            <Grid
              item
              container
              // justifyContent={{
              //   xs: "center",
              //   sm: "space-between",
              //   md: "space-between",
              //   lg: "space-between",
              //   xl: "space-between",
              // }}

              md={3}
              lg={2}
              xl={1.5}
              sm={3}
              xs={5}
              xxs={12}
              key={item.id}
            >
              <ExchangeCard
                item={item}
                index={index}
                sponsor={sponsor}
                onRefresh={handleRefresh}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };
  const discountsGrid = () => {
    return (
      <>
        <Grid
          container
          mt={4}
          rowGap={2}
          columnGap={2}
          justifyContent={discounts.length > 4 ? "space-between" : "flex-start"}
        >
          {discounts?.map((item, index) => (
            <Grid
              item
              container
              justifyContent={"center"}
              md={12}
              lg={2}
              xl={1.5}
              sm={3}
              xs={5}
              xxs={12}
              key={item.id}
            >
              <DiscountCard
                item={item}
                index={index}
                sponsor={sponsor}
                onRefresh={handleRefresh}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };
  return (
    <Container maxWidth="xxl">
      <Box sx={styles.formCard}>
        <Tabs
          value={activeTab} // Use activeTab here
          onChange={handleTabChange} // Handle tab changes
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>

        <Box>
          {activeTab === 0 && exchangesGrid()}

          {activeTab === 1 && discountsGrid()}
        </Box>
      </Box>
    </Container>
  );
};
