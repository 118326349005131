import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Container, Grid, useTheme } from "@mui/material";
import "./home.scss";
import { styles } from "../../styles/styles";
import { InfoCard } from "../../components/cards/InfoCard";
import { ProfileCard } from "../../components/cards/ProfileCard";

import { InfoCardData } from "../../utils/HomeData";

import { useDispatch, useSelector } from "react-redux";
import { getSponsorData } from "../../store/actions/sponsor";
const Home = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sponsor } = useSelector((state) => state.sponsor);
  const { adminData } = useSelector((state) => state.auth);

  const isFirstUse = useRef(true);
  const [sponsorData, setSponsorData] = useState([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setloading] = useState(false);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const sponsorId = adminData?.sponsorId;
  const [activeUsersFromDB, setActiveUsersFromDB] = useState([]);
  const getSponsorFromFB = useCallback(async () => {
    try {
      dispatch(getSponsorData(sponsorId));

      setSponsorData(sponsor);
    } catch (error) {
      console.log(error);
    }
  }, [sponsor]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getSponsorFromFB();
      setSponsorData(sponsor);
      isFirstUse.current = false;
    } else if (!sponsor) {
      getSponsorFromFB();
      setloading(false);

      setSponsorData(sponsor);
    }
  }, [getSponsorFromFB]);

  // useEffect(() => {
  //   console.log(association);
  // }, [association]);

  const renderInfoCards = () => {
    return InfoCardData.map((item, index) => (
      <InfoCard
        key={item.id}
        theme={theme}
        item={item}
        index={index}
        sponsorid={sponsorId}
        usersFromDb={usersFromDb}
        activeUsersFromDB={activeUsersFromDB}
      />
    ));
  };

  return (
    <Container maxWidth="xxl">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <Grid container sx={styles.infoSection} mt={2}>
          <Grid container item md={5}>
            <ProfileCard data={sponsor} />
          </Grid>
          <Grid
            container
            item
            md={7}
            xs={12}
            alignItems={"center"}
            justifyContent={"space-around"}
            columnSpacing={2}
            sx={{
              [theme.breakpoints.down("md")]: {
                margin: "2em auto !important",
              },
            }}
          >
            {renderInfoCards()}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Home;
