import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  getRedeemsFromSponsor,
  getSponsorData,
} from "../../store/actions/sponsor";

import { RedeemCard } from "../../components/products/RedeemCard";
import { RedeemsTable } from "../../components/products/RedeemsTable";
import { useTheme } from "@emotion/react";

const MySwal = withReactContent(Swal);

export const RedeemsPage = () => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { redeems } = useSelector((state) => state.sponsor);
  const theme = useTheme();
  const isFirstUse = useRef(true);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const [value, setValue] = useState(0);

  const { adminData } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  //IMAGE
  const { sponsor } = useSelector((state) => state.sponsor);
  const sponsorId = adminData?.sponsorId;

  const [isDataAvailable, setDataAvailable] = useState(true);

  const [redeemsState, setRedeemsState] = useState([]);

  const getProductsFromDB = useCallback(async () => {
    try {
      dispatch(getRedeemsFromSponsor(sponsorId));
      const redeemedStatus = redeems.filter((r) => r.isRedeemed === true);
      const pendingStatus = redeems.filter((r) => r.status === "pending");
      const pendingRefund = redeems.filter((r) => r.status === "refundRequest");
      const refundedStatus = redeems.filter((r) => r.status === "refunded");
      setRedeemsState({
        all: redeems,
        redeemed: redeemedStatus,
        pending: pendingStatus,
        pendingRefund: pendingRefund,
        refunded: refundedStatus,
      });
      dispatch(getSponsorData(sponsorId));
    } catch (error) {
      console.log(error);
    }
  }, [sponsorId, dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getProductsFromDB();
      isFirstUse.current = false;
    } else if (redeems?.length > 0) {
      const redeemedStatus = redeems.filter((r) => r.isRedeemed === true);
      const pendingStatus = redeems.filter((r) => r.status === "pending");
      const pendingRefund = redeems.filter((r) => r.status === "refundRequest");
      const refundedStatus = redeems.filter((r) => r.status === "refund");
      setRedeemsState({
        all: redeems,
        redeemed: redeemedStatus,
        pending: pendingStatus,
        pendingRefund: pendingRefund,
        refunded: refundedStatus,
      });
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [redeems, getProductsFromDB]);

  useEffect(() => {
    console.log(redeems);
  }, []);

  const handleRefresh = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //   useEffect(() => {
  //     console.log(association);
  //   }, [data]);

  //   console.log(imageUpload);

  const steps = ["Redimidos", "Pendientes", "Refunded"];

  const TabPanel = ({ children, value, index }) => {
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Grid container item>
            {children}
          </Grid>
        )}
      </Box>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const redeemsGrid = () => {
    return (
      <>
        <Grid
          container
          mt={4}
          justifyContent={"space-between"}
          columnGap={{ xs: 1, sm: 1, md: 2 }}
        >
          {redeemsState?.redeemed?.map((item, index) => (
            <Grid
              item
              container
              justifyContent={"center"}
              md={3}
              lg={2}
              xl={1.5}
              sm={3}
              xs={5}
              xxs={12}
              key={item.id}
            >
              <RedeemCard item={item} index={index} sponsor={sponsor} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={styles.formCard} mb={6}>
        <Box mt={4} ml={4}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Redencion de Canjes
          </Typography>
        </Box>
        {/* <Tabs
          value={activeTab} // Use activeTab here
          onChange={handleTabChange} // Handle tab changes
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs> */}
        <TabPanel value={value} index={0}>
          <RedeemsTable
            items={redeemsState.all}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            loading={loading}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <RedeemsTable
            items={redeemsState.redeemed}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            loading={loading}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RedeemsTable
            items={redeemsState.pending}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            loading={loading}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RedeemsTable
            items={redeemsState.pendingRefund}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            loading={loading}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <RedeemsTable
            items={redeemsState.refundedStatus}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            loading={loading}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        {/* <Box>{activeTab === 0 && redeemsGrid()}</Box>
        <Box>{activeTab === 1 && redeemsGrid()}</Box>
        <Box>{activeTab === 2 && redeemsGrid()}</Box> */}
      </Box>
    </Container>
  );
};
