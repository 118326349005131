import { db, storageRef } from "../../firebase/firebase-config";

export const SET_BLOGS = "SET_BLOGS";
export const SET_POSTS = "SET_POSTS";
export const ADD_POST = "ADD_POST";
export const getSponsorBlogs = (sponsorId) => {
  return async (dispatch) => {
    try {
      const blogsRef = db.collection("blogs");

      const blogsSnap = await blogsRef.get();

      const blogs = [];

      blogsSnap.forEach((doc) => {
        const blogData = doc.data();
        blogs.push({
          id: doc.id,
          ...blogData,
        });
      });

      dispatch({
        type: SET_BLOGS,
        blogs: blogs,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllPosts = (sponsorId) => {
  return async (dispatch) => {
    try {
      const ref = db
        .collectionGroup("posts")
        .orderBy("cdate", "asc")
        .where("sponsorId", "==", sponsorId);

      const snap = await ref.get();

      const items = [];

      // Iterate through each post

      for (const doc of snap.docs) {
        const data = doc.data();

        // Fetch the parent blog data
        const blogDoc = await doc.ref.parent.parent.get(); // Adjust this path based on your database structure

        // Construct the combined post and blog object
        const combinedData = {
          id: doc.id,
          ...data,
          blogId: blogDoc.id,
          //imageURL: blogData.imageURL,
        };

        items.push(combinedData);
      }

      dispatch({
        type: SET_POSTS,
        posts: items,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getBlogPosts = (blogId) => {
  return async (dispatch) => {
    try {
      const ref = db
        .collection("blogs")
        .doc(blogId)
        .collection("posts")
        .orderBy("cdate", "desc")

        .get();
      const snap = await ref;
      const items = [];

      snap.forEach((doc) => {
        const data = doc.data();
        items.push({
          id: doc.id,
          ...data,
        });
      });
      console.log(blogId, items);
      dispatch({
        type: SET_POSTS,
        blogId: blogId,
        posts: items,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCategories = async () => {
  try {
    // Fetch categories from Firestore collection
    const snapshot = await db
      .collection("utils")
      .doc("blog")
      .collection("categories")
      .get();

    // Extract categories from snapshot
    const categories = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return categories;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of error
  }
};

//POSTS ADD EDIT

export const addBlogPost = async (blogId, postData) => {
  try {
    // Ensure immutability by creating a new object for postData
    const newData = {
      ...postData,
      cdate: new Date(),
    };

    // Execute Firestore operation within a transaction
    const docRef = await db.runTransaction(async (transaction) => {
      // Construct Firestore reference
      const postRef = db
        .collection("blogs")
        .doc(blogId)
        .collection("posts")
        .doc();

      // Add new document with transaction
      await transaction.set(postRef, newData);

      return postRef; // Return reference to the new document
    });

    // Retrieve the ID of the newly created document
    const postId = docRef.id;

    // Log success message with the document ID
    console.log("Document successfully written with ID:", postId);

    // Return the ID of the new document
    return postId;
  } catch (error) {
    // Log and handle errors appropriately
    console.error("Error adding document:", error);
    throw error; // Re-throw error for handling in caller function or component
  }
};

export const editBlogPost = async (
  blogId,
  oldBlogId,
  postId,
  updatedData,
  imageUpload,
  shouldMovePost
) => {
  try {
    //Should I have to move the post?
    let newData = updatedData;

    if (shouldMovePost) {
      const newPost = await db
        .collection("blogs")
        .doc(blogId)
        .collection("posts")
        .add(updatedData);

      if (imageUpload.length > 0) {
        await createPostImages(imageUpload, blogId, newPost.id);
      } else {
        await updateImageURLs(blogId, newPost.id, []);
      }

      await db
        .collection("blogs")
        .doc(oldBlogId)
        .collection("posts")
        .doc(postId)
        .delete();
    } else {
      console.log(imageUpload, updatedData);
      if (imageUpload.length) {
        const path = `blogs/${blogId}/${postId}/postImage`;
        for (const file of imageUpload) {
          const newImg = await uploadFile(path, file);

          if (newImg) {
            newData = { ...updatedData, imageURLs: [newImg] };
          }
        }

        await db
          .collection("blogs")
          .doc(blogId)
          .collection("posts")
          .doc(postId)
          .update(newData);
      } else {
        const newData = { ...updatedData, imageURLs: [] };
        await db
          .collection("blogs")
          .doc(blogId)
          .collection("posts")
          .doc(postId)
          .update(newData);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateImageURLs = async (blogId, postId, imageURLs) => {
  await db
    .collection("blogs")
    .doc(blogId)
    .collection("posts")
    .doc(postId)
    .set({ imageURLs }, { merge: true });
};

export const createPostImage = async (imageUpload, blogId, id) => {
  const imageName = imageUpload.name;

  try {
    const path = `blogs/${blogId}/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);
    console.log(imageUpload, blogId, id);
    if (fileUrl) {
      const newImg = { imageURL: fileUrl };
      await db
        .collection("blogs")
        .doc(blogId)
        .collection("posts")
        .doc(id)
        .set(newImg, { merge: true });
    }
  } catch (error) {
    console.log(error);
  }
};

export const createPostImages = async (imageUploads, blogId, postId) => {
  console.log("imageUploads:", imageUploads);

  try {
    let imageURLs = imageUploads;

    if (typeof imageUploads[0] !== "string") {
      imageURLs = []; // Array to store imageURLs

      const uploadPromises = imageUploads.map(async (imageUpload, index) => {
        console.log("Processing image:", imageUpload.name);
        const imageName = imageUpload.name;
        const path = `blogs/${blogId}/${postId}/postImage`; // ${imageName}
        const fileUrl = await uploadFile(path, imageUpload);

        if (fileUrl) {
          imageURLs.push(fileUrl); // Add imageURL to the array
        }
      });

      await Promise.all(uploadPromises);
    }

    // Store the array of imageURLs in Firestore
    await db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId)
      .set({ imageURLs }, { merge: true }); // Merge with existing data if any
  } catch (error) {
    console.error(error);
  }
};

export const uploadFile = async (path, file) => {
  //if (file === null && file.length) return;
  if (!(file instanceof File)) return;
  console.log(path, file);
  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};

export const deleteBlogPost = async (blogId, postId) => {
  try {
    // Construct reference to the post document
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    // Delete the post document
    await postRef.delete();

    // Log success message
    console.log("Post successfully deleted:", postId);

    // Return success message or any other indicator if needed
    return "success";
  } catch (error) {
    // Log and handle errors appropriately
    console.error("Error deleting post:", error);
    throw error;
  }
};
