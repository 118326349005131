import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "../pages/protected-routes/protected-routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "../firebase/firebase-config";
import { Login } from "../pages/login/Login";
import SidebarLayout from "../pages/navigation/SidebarLayout";
import { CoinLoader } from "../components/coin-loader/CoinLoader";
import {
  loginUserSuccess,
  setAdmin,
  setIsLoadingAuth,
  setPremiumStatus,
} from "../store/actions/auth";
import Home from "../pages/Home/Home";
import { ForgotPassword } from "../pages/login/ForgotPassword";
import { EditProfile } from "../pages/profile/EditProfile";

import Settings from "../pages/settings/Settings";
import Inquiries from "../pages/inquiries/Inquiries";
import { getSponsorData, getSponsorFromDB } from "../store/actions/sponsor";
import { ProductsPage } from "../pages/products/ProductsPage";
import { RedeemsPage } from "../pages/products/RedeemsPage";
import { ExchangesPage } from "../pages/products/ExchangesPage";
import { DiscountsPage } from "../pages/products/DiscountsPage";
import BlogsPage from "../pages/blogs/BlogsPage";
import PostsPage from "../pages/blogs/PostsPage";

function AppRoutes() {
  const dispatch = useDispatch();
  const { loggedIn, isLoadingAuth, adminData, premiumStatus, admin } =
    useSelector((state) => state.auth);
  const { sponsor } = useSelector((state) => state.sponsor);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        const claims = await user.getIdTokenResult();
        const admin = await getSponsorFromDB(user.uid);

        if (admin) {
          try {
            const adminData = admin.data();
            // console.log(adminData);
            if (adminData) {
              const sponsorId = adminData.sponsorId;
              dispatch(getSponsorData(sponsorId));

              dispatch(setAdmin(adminData));

              dispatch(
                loginUserSuccess(user.toJSON(), true, idToken, user, claims)
              );
              dispatch(setIsLoadingAuth(false));
            }
          } catch (error) {
            console.log(error);
            dispatch(setIsLoadingAuth(false));
          }
        }
      } else {
        dispatch(setIsLoadingAuth(false));
      }
      dispatch(setIsLoadingAuth(false));
    });
  }, [dispatch]);

  useEffect(() => {
    if (sponsor && sponsor.email) {
      // Listen to changes in the premium status of the admin
      const unsubscribe = db
        .collection("sponsors")
        .where("email", "==", sponsor.email)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            console.log("data", data);
            if (data.tier === "elite") {
              dispatch(setPremiumStatus("elite"));
            } else if (data.tier === "premium") {
              dispatch(setPremiumStatus("premium"));
            } else {
              dispatch(setPremiumStatus("basic"));
            }
          });
        });

      // Unsubscribe from premium status changes
      return () => unsubscribe();
    }
  }, [adminData, dispatch]);

  if (isLoadingAuth) {
    const size = {
      width: 125,
      height: 125,
    };
    return <CoinLoader size={size} fullScreen={true} />;
  }

  return (
    <Routes>
      <Route path="/" element={<SidebarLayout />}>
        <Route
          path=""
          element={
            loggedIn ? (
              <Navigate replace to="/home" />
            ) : (
              <Navigate replace to="/auth/login" />
            )
          }
        />

        <Route
          path="/*"
          element={
            <ProtectedRoute isLoggedIn={loggedIn}>
              <Routes>
                <Route
                  path="/auth/login"
                  element={<Navigate replace to="/home" />}
                />
                <Route index path="/home" element={<Home />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/inquiries" element={<Inquiries />} />
                <Route path="/exchanges" element={<ExchangesPage />} />
                <Route path="/discounts" element={<DiscountsPage />} />
                <Route path="/redeems" element={<RedeemsPage />} />
                <Route path="/blogs" element={<BlogsPage />} />
                <Route path="/profile" element={<EditProfile />} />
                <Route path="/posts" element={<PostsPage />} />
              </Routes>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <h1
            className="d-flex align-items-center vh-100"
            style={{ marginLeft: "30%" }}
          >
            Lo sentimos , esta página no esta disponible
          </h1>
        }
      />

      <Route path="/auth/forgotpassword" element={<ForgotPassword />} />
      {!loggedIn && <Route path="/auth/login" element={<Login />}></Route>}
    </Routes>
  );
}

export default AppRoutes;
