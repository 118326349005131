export const categories = [
  "Bar/Vida Nocturna",
  "Restaurante/Cafetería",
  "Tienda deportiva",
  "Outfit",
  "Servicios de salud",
  "Recreación al aire libre",
  "Instalación deportiva",
  "Entretenimiento Indoor",
  "Hospedaje",
  "Supermercado/Abarrotes",
  "Tienda de tecnología",
  "Servicios educativos",
  "Estetica/Spa/Cuidado personal",
  "Productos especializados",
  "Eventos deportivos",
  "Otro",
];
export const categoriesForSector = {
  "Alimento y Consumo": [
    "Restaurante/Cafetería",
    "Bar/Vida Nocturna",
    "Bar",
    "Supermercado/Abarrotes",
    "Otro",
  ],
  Entretenimiento: ["Bar/Vida Nocturna", "Recreación al aire libre", "Otro"],
  "Servicios de salud": [
    "Servicios de salud",
    "Estetica/Spa/Cuidado personal",
    "Otro",
  ],
  Deporte: [
    "Eventos deportivos",
    "Instalación deportiva",
    "Entretenimiento Indoor",
    "Eventos deportivos",
    "Otro",
  ],
  Alojamiento: ["Hospedaje", "Otro"],
  "Compra de productos": [
    "Tienda deportiva",
    "Tienda de tecnología",
    "Supermercado/Abarrotes",
    "Productos especializados",
    "Outfit",
    "Otro",
  ],
};
export const sectors = [
  { name: "Alimento y Consumo" },
  { name: "Entretenimiento" },
  { name: "Servicios de salud" },
  { name: "Deporte" },
  { name: "Alojamiento" },
  { name: "Compra de productos" },
];

export const countryList = [{ name: "Costa Rica" }];
